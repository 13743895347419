import { useState, useEffect } from 'react';

// type TimeoutCallback = () => void;

export default function useTimeout(callback, delay) {
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    const id = setTimeout(callback, delay);

    return () => clearTimeout(id);
  }, [callback, delay]);

  const reset = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(callback, delay);
    setTimerId(newTimerId);
  };

  return reset;
}
