import React from 'react';
import { gravityLogo } from '../../constant/Images';

function OnboardingLayout({ children }) {
  return (
    <div className="flex h-screen w-screen">
      <div className="w-2/12 bg-black justify-center p-8">
        <img src={gravityLogo} alt="logo" className="h-12" />
        <div className="h-full mt-20 w-full">
          <div className="">
            <div className="flex items-center">
              <div className="w-7  h-7 mr-2 bg-stone-950 rounded-full border border-rose-400" />
              <p className="text-sm">Center Details</p>
            </div>
            <div className="w-0.5 h-10 ml-3 items-center bg-gray-500" />
            <div className="flex items-center">
              <div className="w-7 h-7 mr-2 bg-stone-950 rounded-full border border-cyan-300" />
              <p className="text-sm">Amenities/Equip</p>
            </div>
            <div className="w-0.5 h-10 ml-3 items-center bg-gray-500" />
            <div className="flex items-center">
              <div className="w-7 h-7 mr-2 bg-stone-950 rounded-full border border-red-400" />
              <p className="text-sm">Bank Details</p>
            </div>
            <div className="w-0.5 h-10 ml-3 items-center bg-gray-500" />
            <div className="flex items-center">
              <div className="w-7 h-7 mr-2 bg-stone-950 rounded-full border border-indigo-400" />
              <p className="text-sm">Center Timings</p>
            </div>
            <div className="w-0.5 h-10 ml-3 items-center bg-gray-500" />
            <div className="flex items-center">
              <div className="w-7 h-7 mr-2 bg-stone-950 rounded-full border border-yellow-300" />
              <p className="text-sm">Create Membership</p>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default OnboardingLayout;
