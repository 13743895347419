'use client'
import { type } from 'os';
import React, { useState } from 'react'


const Svg18 = () => {

    return (
    <div>
          <svg viewBox="0 0 1024 1024" 
       
          fill="currentColor" height="1em" width="1em">
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
      </svg>
    </div>
  )
}

export default Svg18