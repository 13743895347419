import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LeadsCall } from "../../api/Home/Leads";
import Svg12 from "../../../svg/Svg12";
import { nameShortner } from "../../utils/Validator";
import "./styles.css";

function LeadsCard() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [leads, setLeads] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("Token") || "");
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await LeadsCall(token);
      setLeads(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const top4Leads = leads.slice(0, 4);

  return (
    <div className="bg-zinc-300 bg-opacity-5 rounded-md backdrop-blur-2xl mt-3 p-3 ">
      <div className="flex items-center justify-between border-b-2 pb-4 border-neutral-700">
        <div className="flex items-center gap-2">
          <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
            <path d="M6 23H2a1 1 0 01-1-1v-8a1 1 0 011-1h4a1 1 0 011 1v8a1 1 0 01-1 1z" />
            <path d="M14 23h-4a1 1 0 01-1-1V2a1 1 0 011-1h4a1 1 0 011 1v20a1 1 0 01-1 1z" />
            <path d="M22 23h-4a1 1 0 01-1-1V10a1 1 0 011-1h4a1 1 0 011 1v12a1 1 0 01-1 1z" />
          </svg>
          <p>Leads</p>
        </div>
        <div>
          <div className="flex justify-center items-center gap-4">
            <Link to={"/Leads"} state={{ isOpen: true }}>
              <div className="text-sm flex justify-center items-center gap-3 rounded-lg text-yellow-300 p-2">
                {" "}
                <span>
                  <Svg12 />
                </span>{" "}
                <span>Create Lead</span>
              </div>
            </Link>
            <Link to={"/Leads"}>
              <p className="text-sm">View more</p>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="">
        <div className="h-[400px] overflow-y-auto">
          <table className="w-full">
            <thead>
              <tr>
                <th className="py-4 px-4 text-sm text-left">Prospect Name</th>
                <th className="py-2 px-4 text-sm text-left">Plan Type</th>
                <th className="py-2 px-4 text-sm text-left">Contact</th>
                <th className="py-2 px-4 text-sm text-left">Plan Name</th>
                <th className="py-2 px-4 text-sm text-left">Note</th>
                <th className="py-2 px-4 text-sm text-left">Mode</th>
                <th className="py-2 px-4 text-sm text-left">Status</th>
              </tr>
            </thead>
            <tbody className="h-76 text-xs overflow-y-auto overflow-x-hidden">
              {leads.map((lead) => (
                <tr key={lead.id}>
                  <td className="p-4 text-left border-b border-neutral-700">
                    {lead.customerName}
                  </td>
                  <td className="p-4 w-48 text-left border-b border-neutral-700">
                    {lead.interest?.package?.name || "NULL"}
                  </td>
                  <td className="p-4 text-left border-b border-neutral-700">
                    {lead.mobileNumber}
                  </td>
                  <td className="p-4 text-left border-b border-neutral-700">
                    {lead.interest?.package?.package_type || "NULL"}
                  </td>
                  <td className="p-4 w-64 text-left border-b border-neutral-700">
                    {lead.note}
                  </td>
                  <td className="p-4 text-left border-b border-neutral-700">
                    {lead.mode}
                  </td>
                  <td className="px-4 text-left border-b border-neutral-700">
                    <p
                      className={`text-center py-1 px-2 rounded-full ${
                        lead.status === "Converted"
                          ? "bg-green-500 bg-opacity-30"
                          : lead.status === "Pending"
                          ? "bg-yellow-500 bg-opacity-30"
                          : lead.status === "In Discussion"
                          ? "bg-blue-400 bg-opacity-30"
                          : lead.status === "Attempted"
                          ? "bg-purple-500 bg-opacity-30"
                          : lead.status === "Disqualified"
                          ? "bg-red-500 bg-opacity-30"
                          : "" // Add an empty string as a fallback
                      }`}
                    >
                      {lead.status}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
      {/* </div> */}
      <div className="px-4 max-w-full overflow-x-auto scroll-hide dropdown-scroll max-h-[40vh] overflow-y-scroll lg:max-h-[calc(100vh-40vh)] 3xl:max-h-[40vh] 4xl:max-h-[48vh] ">
        <table className="w-full mt-10 overflow-y-scroll h-[80vh]">
          <thead>
            <tr>
              <th className="p-2 text-left">Prospect Name</th>
              <th className="p-2 text-left">Plan Type</th>
              <th className="p-2 text-left">Contact</th>
              <th className="p-2 text-left">Plan Name</th>
              <th className="p-2 text-left">Note</th>
              <th className="p-2 text-left">Mode</th>
              <th className="p-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody className=" max-h-64 text-xs overflow-y-auto">
            {leads.map((lead) => (
              <tr key={lead.id}>
                <td className="p-2 text-left border-b border-neutral-700">
                  {lead.customerName}
                </td>
                <td className="p-2 w-44 text-left border-b border-neutral-700">
                  {lead.interest?.package?.name || "NULL"}
                </td>

                <td className="p-2 text-left border-b border-neutral-700">
                  {lead.mobileNumber}
                </td>
                <td className="p-2 text-left border-b border-neutral-700">
                  {lead.interest?.package?.package_type || "NULL"}
                </td>

                <td className="p-2 text-left border-b border-neutral-700">
                  {nameShortner(lead.note, 10)}
                </td>
                <td className="p-2 text-left border-b border-neutral-700">
                  {lead.mode}
                </td>
                <td className="p-2 text-left border-b border-neutral-700">
                  <p
                    className={`text-center py-2 rounded-full ${
                      lead.status === "Converted"
                        ? "bg-green-500 bg-opacity-30"
                        : lead.status === "Pending"
                        ? "bg-yellow-500 bg-opacity-30"
                        : lead.status === "In Discussion"
                        ? "bg-blue-400 bg-opacity-30"
                        : lead.status === "Attempted"
                        ? "bg-purple-500 bg-opacity-30"
                        : lead.status === "Disqualified"
                        ? "bg-red-500 bg-opacity-30"
                        : "" // Add an empty string as a fallback
                    }`}
                  >
                    {lead.status}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LeadsCard;
