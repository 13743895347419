import axios, { AxiosResponse } from 'axios';
import { assert } from 'console';

const instance = axios.create({
  baseURL: 'https://wellverse.fit/v1/',
});


  async function getCenterDetails(token) {
    try {
      const response= await instance.get('/center/details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        return response.data // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      // console.log(error);
    }
  }

export {getCenterDetails}
