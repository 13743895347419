import CheckIn from '../pages/CheckIn/CheckIn';
import GrowthTool from '../pages/GrowthTools/GrowthTool';
import HomePage from '../pages/Home/HomePage';
import ExpiredMembership from '../pages/Home/ExpiredMembership';
import Leads from '../pages/Home/Leads';
import PayOnCenter from '../pages/Home/PayOnCenter';
import Listing from '../pages/Listing/Listing';
import Membership from '../pages/Membership/Membership';
import Member from '../pages/Membership/Member';
import Packages from '../pages/Packages/Packages';
import Reviews from '../components/Reviews/Reviews';
const coreRoutes = [
  {
    path: '/Main',
    title: 'HomePage',
    component: HomePage,
  },
  {
    path: '/Main/Listing',
    title: 'HomePage',
    component: Listing,
  },
  {
    path: '/Main/PayOnCenter',
    title: 'HomePage',
    component: PayOnCenter,
  },
  {
    path: '/Main/ExpiredMembership',
    title: 'HomePage',
    component: ExpiredMembership,
  },
  {
    path: '/Leads',
    title: 'Leads',
    component: Leads,
  },
  {
    path: '/CheckIN',
    title: 'CheckIN',
    component: CheckIn,
  },
  {
    path: '/GrowthTool',
    title: 'Growth',
    component: GrowthTool,
  },
  {
    path: '/Membership',
    title: 'Membership',
    component: Membership,
  },
  {
    path: '/Membership/:memberId',
    title: 'Membership',
    component: Member,
  },
  {
    path: '/Package',
    title: 'Package',
    component: Packages,
  },
  {
    path: '/Reviews',
    title: 'Reviews',
    component: Reviews
  }
];

const routes = [...coreRoutes];
export default routes;
