import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { centerAmenitiesEquipmentsCall } from '../../api/Authentication/index';
import Button from '../../components/Button/Button';
import SelectTags from '../../components/SelectTags/SelectTags';
// import OnboardingLayout from 'renderer/app/layout/Onboarding';
import {motion} from 'framer-motion'
const predefinedAmities = [
  'Air Conditioner',
  'Parking',
  'Locker Rooms',
  'Personal Training ',
  'Group Exercise Classes',
  'Sauna or Steam Room',
  'Swimming Pool',
  'Childcare Services',
  '24-Hour Access',
  // Add more amenities as needed
];

const predefinedEuipments = [
  'Childcare Services',
  '24-Hour Access',
  // Add more amenities as needed
];

function AmenitiesEquipmentListing() {
  const [selectedAmities, setSelectedAmities] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const handleAmitiesChange = (selectedTags) => {
    setSelectedAmities(selectedTags);
  };

  useEffect(() => {
    // Retrieve the token from local storage
    const storedToken = localStorage.getItem('Token');

    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleEuipmentChange = (selectedTags) => {
    setSelectedEquipments(selectedTags);
  };

  return (
    <div>
      <motion.div
      initial={{ opacity: 0 }} // Set the initial opacity to 0
      animate={{ opacity: 1 }} // Animate to opacity 1
      transition={{ duration: 0.5, ease: 'easeInOut' }} // Set the duration and easing of the animation
      className="w-1/2">
        <p className="text-xl">Select Amenities</p>
        <SelectTags list={predefinedAmities} onChange={handleAmitiesChange} />

        <p className="text-xl mt-10">Select Equipments</p>
        <SelectTags
          list={predefinedEuipments}
          onChange={handleEuipmentChange}
        />
        <div className="w-1/2">
          <Button title="Confirm" />
        </div>
      </motion.div>
    </div>
  );
}

export default AmenitiesEquipmentListing;
