import React, { useCallback, useEffect, useState } from "react";
import {
  createCheckIn,
  deleteMembership,
  getActiveMember,
  getMembershipDetails,
} from "../../api/Home/Membership";
import MemberCard from "../../components/Card/MemberCard";
import Loading from "../../components/Loading/Loading";
import Popup from "../../components/Popup/Popup";
import { motion } from "framer-motion";

function formatDate(isoDate) {
  const date = new Date(isoDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

function Membership() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [member, setMember] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null); // New state
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("General"); // Default value is Flexible
  const [sortOrder, setSortOrder] = useState("latest"); // State to track sort order
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await getActiveMember(token);
      setMember(response.members);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const isDropdown =
        e.target instanceof Element && e.target.closest(`#dropdown`);
      const isButton =
        e.target instanceof Element && e.target.closest("#button");

      if (!isDropdown && !isButton && isPopupVisible) {
        setPopupVisible(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isPopupVisible, setPopupVisible]);
  const MembershipDetails = ({ id }) => {
    const [item, setItem] = useState({});

    const getMemberDetails = async () => {
      try {
        const details = await getMembershipDetails(token, id);
        setItem(details.details);
      } catch (error) {
        setError("Error fetching member details");
      }
    };

    useEffect(() => {
      getMemberDetails();
    }, [id]);

    const handleCheckInButton = async () => {
      try {
        const response = await createCheckIn(token, item._id);
        if (response) {
          handlePopupClose();
        }
      } catch (error) {
        setError("Error fetching member details");
      }
    };

    const openConfirmationDialog = () => {
      setShowConfirmationDialog(true);
    };

    const closeConfirmationDialog = () => {
      setShowConfirmationDialog(false);
    };

    const handleDeleteButton = (event) => {
      event.stopPropagation();
      openConfirmationDialog();
    };

    const handleConfirmDelete = async () => {
      try {
        const response = await deleteMembership(token, item._id);
        if (response) {
          closeConfirmationDialog();
          handlePopupClose();
          fetchData();
        }
      } catch (error) {
        setError("Error deleting member");
      }
    };

    const handleCancelDelete = () => {
      closeConfirmationDialog();
    };
    // Delete Function

    // const handleDeleteButton = async () => {
    //   try {
    //     const response = await deleteMembership(token, item._id);
    //     // console.log(response);
    //     if (response) {
    //       handlePopupClose();
    //       fetchData();
    //     }
    //   } catch (error) {
    //     setError("Error fetching member details");
    //   }
    // };
    const handleSortOrderChange = (order) => {
      setSortOrder(order);
    };

    // Sort members based on sortOrder state
    const sortedMembers = member.sort((a, b) => {
      if (sortOrder === "oldest") {
        return new Date(a.startDate) - new Date(b.startDate);
      } else {
        // Default to latest
        return new Date(b.startDate) - new Date(a.startDate);
      }
    });
    const noMembersFound = filteredMembers.length === 0 && !loading;
    // const reversedMembers = [...filteredMembers].reverse();

    return (
      <div className="mt-20 ">
        <div className={`flex justify-center items-center h-32  `}>
          {" "}
          {/* Apply flex and justify-center */}
          <img
            src="/profile.jpg"
            alt={"member"}
            className="h-full  w-32 object-cover rounded-full"
          />
        </div>

        <div className="mt-10 text-left px-5">
          {/* <p className="text-lg">{item.user?.name || "Loading"}</p> */}

          <p className="text-lg">{item.user?.name || "Loading"}</p>
          <p>Phone : {item.user?.phoneNumber || "Loading"}</p>
          {/* <p>Email : {item.user?.phoneNumber || 'Loading'}</p> */}
        </div>
        <div>
          {/* <div className="flex mt-5 justify-between px-5 items-start w-full">
            <div className="">
              <p className="text-[12px] font-bold">Plan Type</p>
              <p className="py-1 text-lg">{item.membershipType || "Loading"}</p>
            </div>
            <div className="">
            <p className="text-[12px] w-[112px] font-bold text-left">Plan Name</p>
              <p className="py-1 text-lg whitespace-nowrap">{item.package?.name || "Loading"}</p>
            </div>
          </div> */}
          {/* <div className="flex mt-5 justify-between px-5 items-center">
            <div className="">
            <p className="text-[12px] font-bold">Plan Price</p>
              <p className="py-1 text-lg">Rs {item.package?.price || "Loading"} /-</p>
            </div>
            <div className="">
            <p className="text-[12px] font-bold">Purchase Date</p>
              <p className="py-1 text-lg">{ formatDate(item.purchaseDate) || "Loading"}</p>
            </div>
          </div> */}

          <div className="flex justify-between item-center gap-5 px-5 py-5 text-sm">
            <div className="flex flex-col items-start justify-start gap-5">
              <div className="">
                <p className="text-[12px] font-bold">Plan Type</p>
                <p className="py-1 text-lg">
                  {item.membershipType || "Loading"}
                </p>
              </div>
              <div className="">
                <p className="text-[12px] font-bold">Plan Price</p>
                <p className="py-1 text-lg">
                  Rs {item.package?.price || "Loading"} /-
                </p>
              </div>
              <div className="">
                <p className="text-[12px] font-bold">Start Date</p>
                <p className="py-1 text-lg">
                  {formatDate(item.startDate) || "Loading"}
                </p>
              </div>
            </div>

            <div className="flex flex-col items-start justify-start gap-5">
              <div className="">
                <p className="text-[12px] w-[112px] font-bold text-left">
                  Plan Name
                </p>
                <p className="py-1 text-lg whitespace-nowrap">
                  {item.package?.name || "Loading"}
                </p>
              </div>
              <div className="">
                <p className="text-[12px] font-bold">Purchase Date</p>
                <p className="py-1 text-lg">
                  {formatDate(item.purchaseDate) || "Loading"}
                </p>
              </div>
              <div className="">
                <p className="text-[12px] font-bold">Expire Date</p>
                <p className="py-1 text-lg">
                  {formatDate(item.expiredDate) || "Loading"}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="flex mt-5 justify-between px-5 items-center">
            <div className="">
              <p className="text-[12px] font-bold">Start Date</p>
              <p className="py-1 text-lg">{ formatDate(item.startDate) || "Loading"}</p>
            </div>
            <div className="">
            <p className="text-[12px] font-bold">Expire Date</p>
              <p className="py-1 text-lg">{ formatDate(item.expiredDate) || "Loading"}</p>
            </div>
          </div> */}
        </div>
        <div className="flex">
          <button
            onClick={handleCheckInButton}
            className="w-full py-4 justify-center items-center mt-10 bg-green-600 rounded"
          >
            <p>Check In Member</p>
          </button>
          {/* <button
            onClick={handleDeleteButton}
            className="px-5 ml-2 py-4 justify-center items-center mt-10 bg-red-600 rounded"
          >
            <p>Delete</p>
          </button> */}

          <button
            onClick={handleDeleteButton}
            className="px-5 ml-2 py-4 justify-center items-center mt-10 bg-red-600 rounded"
          >
            <p>Delete</p>
          </button>

          {showConfirmationDialog && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 flex items-center justify-center backdrop-blur-sm"
            >
              <div className="backdrop-blur-2xl bg-black/90 text-white p-8 max-w-md rounded shadow-lg text-center">
                <p>Are you sure you want to delete this member?</p>
                <p className="text-sm">(This will affect your revenue)</p>
                <div className="mt-4 w-full">
                  <button
                    onClick={handleConfirmDelete}
                    className="bg-red-600 text-white px-4 py-2  mr-2 rounded"
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleCancelDelete}
                    className="bg-green-500  text-white px-4 py-2  ml-2 rounded"
                  >
                    No
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    );
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter members based on the search term
  const filteredMembers = member.filter((member) => {
    // return member.name.toLowerCase().includes(searchTerm.toLowerCase());
    return (
      member.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedOption === "Flexible" ? member.planType === "Flexible" : true)
    );
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };
  // Sort members based on sortOrder state
  const sortedMembers = member.sort((a, b) => {
    if (sortOrder === "oldest") {
      return new Date(a.startDate) - new Date(b.startDate);
    } else {
      // Default to latest
      return new Date(b.startDate) - new Date(a.startDate);
    }
  });

  // Filter and then sort members
  const filteredAndSortedMembers = sortedMembers.filter((member) => {
    return (
      member.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedOption === "Flexible" ? member.planType === "Flexible" : true)
    );
  });

  const noMembersFound = filteredMembers.length === 0 && !loading;
  const reversedMembers = [...filteredMembers].reverse();
  return (
    <div className={`${isPopupVisible ? "overflow-hidden" : ""}`}>
      {isPopupVisible && (
        <Popup title="Membership Details" onClose={handlePopupClose}>
          <MembershipDetails id={selectedMemberId} />
        </Popup>
      )}
      <p>All Membership</p>
      <div
        className={`mt-10 flex w-full justify-start items-start flex-col ${
          isPopupVisible
            ? "opacity-25 transition-all duration-500 "
            : "opacity-100 transition-all ease-in-out duration-500"
        }`}
      >
        <div className="flex gap-4 justify-start item-start">
          <select
            className="bg-transparent text-sm rounded px-2 h-10 bg-#0A0A0A border focus:ring-purple-600 block "
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option className="bg-black px-2">Flexible</option>
            <option className="bg-black px-2">General</option>
          </select>
          <div className="flex justify-between mb-4">
            <div class="flex justify-center gap-4 ">
              <button
                onClick={() =>
                  handleSortOrderChange(
                    sortOrder === "oldest" ? "latest" : "oldest"
                  )
                }
                className="bg-[#A855F7]  mx-5 text-white font-semibold py-2 px-4 rounded shadow transition duration-200 ease-in-out"
              >
                {sortOrder === "oldest" ? "Oldest" : "Latest"}
              </button>
            </div>
            <input
              className="bg-transparent px-4 bg-white bg-opacity-10 p-2 rounded"
              placeholder="Search by name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div>
            {/* Pay on center  */}
            {/* <button className="bg-blue-500 p-2 rounded">
            <p>Pay on center request</p>
          </button> */}
          </div>
        </div>
        <div className="container">
          <div
            className={`grid  lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ${
              isPopupVisible ? "opacity-25 transition-all duration-300 " : ""
            } `}
          >
            {loading && <Loading />}
            {noMembersFound && <p>No membership found.</p>}
            {filteredAndSortedMembers.map((member) => (
              <MemberCard
                key={member.id}
                onClick={() => {
                  setSelectedMemberId(member.id);
                  setPopupVisible(true);
                }}
                member={member}
              />
            ))}
          </div>
        </div>
      </div>
      {/* <div className="container">
        {loading && <Loading />}
        {noMembersFound && <p className="mt-14 ml-2">No membership found.</p>}
        {!noMembersFound && (
          <div className={`grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ${isPopupVisible ? "opacity-25 transition-all duration-300 ": ""} `}>
            {reversedMembers.map((member) => (
              <MemberCard
                key={member.id}
                onClick={() => {
                  setSelectedMemberId(member.id);
                  setPopupVisible(true);
                }}
                member={member}
              />
            ))}
          </div>
        )}
      </div> */}
    </div>
  );
}

export default Membership;
