import React, { useState } from 'react';

// interface SelectTagsProps {
//   list: string[];
//   onChange: (selectedTags: string[]) => void;
// }

function SelectTags({ list, onChange }) {
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
    onChange([...selectedTags]); // Pass a copy of the updated selectedTags to the parent
  };

  return (
    <div className="my-4">
      <div className="mt-4 flex flex-wrap">
        {list.map((tag) => (
          <div
            key={tag}
            className={`flex items-center rounded-lg p-2 border space-x-2 mt-2 mr-2 cursor-pointer ${
              selectedTags.includes(tag) ? 'border-purple-500 border-2' : ''
            }`}
            onClick={() => toggleTag(tag)}
          >
            <span className="text-sm">{tag}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectTags;
