import React, { useState } from 'react'
import CenterDetails from '../OnBoarding/CenterDetails';
import CenterDetailsListing from './CenterDetails';
import AmenitiesEquipmentListing from './AminitesEquipement';
import BankDetailsListing from './BankDetails';

const Tab1 = () => {
  return <CenterDetailsListing/>
};

const Tab2 = () => {
  return <AmenitiesEquipmentListing/>
};

const Tab3 = () => {
  return <BankDetailsListing/>
}


function Listing() {
  const [activeTab, setActiveTab] = useState(1);

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return <Tab1 />;
      case 2:
        return <Tab2 />;
      case 3:
        return <Tab3 />;
      default:
        return <Tab1 />;
    }
  };
  return (
    <div className="mt-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <p className="ml-2">Edit Fitverse Listing</p>
        </div>
      </div>
      <div>
      <div className="flex space-x-4 gap-5 p-2 mt-5">
        <button
          className={`${
            activeTab === 1 ? 'border-b-2 border-purple-500' : ''
          }`}
          onClick={() => setActiveTab(1)}
        >
          Center Details
        </button>
        <button
          className={`${
            activeTab === 2 ? 'border-b-2 border-purple-500' : ''
          } `}
          onClick={() => setActiveTab(2)}
        >
          Amenities/Equipment
        </button>
        <button
          className={`${
            activeTab === 3 ? 'border-b-2 border-purple-500' : '' 
          }`}
          onClick={() => setActiveTab(3)}
        >
          Bank Details
        </button>
      </div>
      <div className="mt-4">{renderTabContent()}</div>
      </div>      
    </div>
  )
}

export default Listing