import React from 'react'

const Svg12 = () => {
  return (
    <div>
          <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 5V19"
                      stroke="#F2DA05"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#F2DA05"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
    </div>
  )
}

export default Svg12