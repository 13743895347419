
import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import TextForm from '../../components/TextInput/TextForm';
import { gravityLogo, loginRight } from '../../../app/constant/Images';
// import { useHistory } from 'react-router-dom';
import { LoginCall } from '../../api/Authentication/index';
import { validateEmail, validatePassword } from '../../../app/utils/Validator';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import useTimeout from '../../hooks/useTimeout';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Add state for error message
  // const history = useHistory();
  const resetErrorTimeout = useTimeout(() => {
    setError(''); // Clear the error message
  }, 5000); // 5000 milliseconds (5 seconds)

  const handleOnPress = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setError('please double-check your email and try again.');
      resetErrorTimeout();
      return;
    }

    if (!validatePassword(password)) {
      setError('please double-check your password and try again'); // Handle invalid password
      resetErrorTimeout();
      return;
    }
    setLoading(true);

    try {
      const response = await LoginCall(email, password);
      if (response.token) {
        localStorage.setItem('Token', response.token);
        setError('You have successfully log in'); // Handle invalid password
        if (response.onBoarded === false) {
          navigate('/onboarding/Amenities');
          // window.location.href = '/onboarding/centerDetails';
          // history.push('/onboarding/centerDetails');
        } else {
          navigate('/home');
        }
        resetErrorTimeout();
      }
    } catch (e) {
      setError('Invalid email and password and try again'); // Handle invalid password
      resetErrorTimeout();
    }
    setLoading(false);
  };

  return (
    <div className="flex h-screen w-screen">
      {loading ? <Loading /> : null}
      {error && <ErrorMessage message={error} />}
      {/* LEFT COMPONENT */}
      <div className="w-1/2 flex items-center justify-center p-8">
        <div className="w-3/4 p-4 rounded-lg">
          <img
            className="h-12 w-auto mb-10"
            src={gravityLogo}
            alt="Gravity-logo"
          />
          <div>
            <p className="text-2xl font-semibold">Log in</p>
            <p className="text-sm font-semibold text-yellow-300">
              Welcome to Fitverse
            </p>
            <p className="text-xs text-gray-500 mb-6">
              Enter your credential to access the Gravity dashboard
            </p>
          </div>
          <form onSubmit={handleOnPress} >
          <TextForm
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeHolder="Enter your email"
          />
          <TextForm
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeHolder="Enter your password"
            secure
          />
          <Button title="Log In" type="submit"  onPress={handleOnPress} />
          </form>
        </div>
      </div>
      {/* RIGHT COMPONENT */}
      <div className="w-1/2">
        <img src={loginRight} alt="Login-Right" />
      </div>
    </div>
  );
}

export default LoginPage;
