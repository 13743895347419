import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMainDetails } from '../../api/Home/Main';
import { motion } from 'framer-motion';
function MainCardOne() {
  const [data, setData] = useState([]);
  const [token, setToken] = useState(
    localStorage.getItem('Token')
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await getMainDetails(token);
      setData(response);
    } catch (error) {
    } finally {
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <div className="flex justify-between gap-3">
        <motion.div 
        initial={{ opacity: 0 }} // Set the initial opacity to 0
        animate={{ opacity: 1 }} // Animate to opacity 1
        transition={{ duration: 0.5, ease: 'easeIn' }} // Set the duration and easing of the animation
        className="p-3 mr-3 w-full py-8 bg-zinc-300 bg-opacity-5  rounded-md backdrop-blur-2xl">
          <p className="">Total Revenue</p>
          <motion.p 
           initial="hidden"
           animate="visible"
           variants={ textVariants}
           transition={{ duration: 0.2 }}
          className="font-semibold text-2xl text-blue-400 mt-1">₹ {data.totalRevenue}</motion.p>
        </motion.div>
        <motion.div
         initial={{ opacity: 0 }} // Set the initial opacity to 0
         animate={{ opacity: 1 }} // Animate to opacity 1
         transition={{ duration: 0.5, ease: 'easeIn' }} // Set the duration and easing of the animation
        className="p-3 w-full mr-3 py-8 bg-zinc-300 bg-opacity-5  rounded-md backdrop-blur-2xl">
          <p className="">Active membership</p>
          <motion.p 
          initial="hidden"
          animate="visible"
          variants={ textVariants}
          transition={{ duration: 0.2 }}
          className="font-semibold text-2xl text-green-500 mt-1">{data.activeMember}</motion.p>
        </motion.div>
        <Link className='p-3 w-full mr-3 py-8 bg-zinc-300 bg-opacity-5' to="/Main/PayOnCenter">
          <p className="">Pay on center requests</p>
          <motion.p 
          initial="hidden"
          animate="visible"
          variants={ textVariants}
          transition={{ duration: 0.2 }}
          className="font-semibold text-2xl text-yellow-400 mt-1">{data.payOnCenterRequestCount}</motion.p>
        </Link>
        <Link to={"/Main/ExpiredMembership"} className="p-3 w-full py-8  bg-zinc-300 bg-opacity-5  rounded-md backdrop-blur-2xl">
          <p className="">Expired Membership</p>
          <motion.p
          initial="hidden"
          animate="visible"
          variants={ textVariants}
          transition={{ duration: 0.2 }}
          className="font-semibold text-2xl text-red-500 mt-1">{data.expiredMember}</motion.p>
        </Link>
      </div>
    </div>
  );
}

export default MainCardOne;
