import React, { useCallback, useEffect, useState } from "react";
import { getCheckIn } from "../../api/Home/Membership";
import { motion } from "framer-motion";
import Svg10 from "../../../svg/Svg10";
import Svg11 from "../../../svg/Svg11";
import { MdCancel } from "react-icons/md";
import Loading from "../../components/Loading/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Styles.css"
import { IoIosArrowDown } from "react-icons/io";

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${
    date.getFullYear() % 100
  }`;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours > 12 ? hours - 12 : hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${hours >= 12 ? "PM" : "AM"}`;

  return `${formattedDate}\  ${formattedTime}`;
}

function CheckIn() {
  const [data, setData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("Token") || "");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Step 1: State for search query
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedOption, setSelectedOption] = useState('All'); // Default value is Flexible

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selected date when changed
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getCheckIn(token);
      setData(response.checkIns);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const filteredData = data.filter((item) =>
  //   item.user?.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const filteredData = data.filter((item) => {
    const checkinDate = new Date(item.checkinTime);
    const matchesSearchQuery =
      !searchQuery ||
      item.user?.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesSelectedDate =
      !selectedDate ||
      checkinDate.toDateString() === selectedDate.toDateString();
      // const matchesSelectedOption =
      // selectedOption === "All" ||
      // item.membership?.membershipType === selectedOption;  
      const matchesSelectedOption =
      selectedOption === "All" ||
      (selectedOption === "Flexible" &&
        item.membership?.membershipType === "flexible") ||
      (selectedOption === "General" &&
        item.membership?.membershipType === "general");

      return matchesSearchQuery && matchesSelectedDate && matchesSelectedOption;
    });

  const handleCancelDate = () => {
    setSelectedDate(""); // Reset selected date when canceled
    setSearchQuery(""); // Clear search query
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <div className="flex items-center">
        <Svg10 />
        <p className="ml-2">Check-In Panel</p>
      </div>
      <div className="mt-10 flex">
        {/* <div className="flex items-center gap-2 p-2 border-white border rounded">
         <Svg11 />
          <p className='text-sm'>Today</p>
          
        </div> */}
        <div className="relative">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            placeholderText="dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
            className="border border-gray-300 rounded h-14 p-4 w-11/12 mb-5 bg-transparent cursor-pointer"
          />
          <span className="absolute right-8 top-5">
            <MdCancel onClick={handleCancelDate} className="cursor-pointer" />
          </span>
        </div>

        <input
          className="bg-transparent px-4 w-3/12 h-14 bg-white bg-opacity-10 ml-4 rounded"
          placeholder="Search by name"
          value={searchQuery} // Step 1: Bind the input value to the searchQuery state
          onChange={(e) => setSearchQuery(e.target.value)} // Step 1: Handle input changes
        />
      </div>
      <div className="relative w-3/12 flex">
        <select
          className="bg-transparent text-sm custom-select px-2 py-0.5  bg-#0A0A0A border-b-2 focus:ring-purple-600 focus:border-transparent block "
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <option className="bg-black">All</option>
          <option className="bg-black">Flexible</option>
          <option className="bg-black">General</option>
        </select>
        <span className="absolute right-64 top-1">
        <IoIosArrowDown />
        </span>
      </div>
      {filteredData.length === 0 && !loading && (
      <p className="text-center w-5/12 mt-14">No Check-Ins Found</p>
    )}

    {filteredData.length > 0 && (
      <motion.table className="w-1/2 mt-10">
        <thead>
          <tr>
            {/* Table headers */}
            <th className="py-2 text-left text-yellow-400">Client Name</th>
            <th className="py-2 text-left">Plan Type</th>
            <th className="py-2 text-left text-red-400">Time</th>
          </tr>
        </thead>
        <tbody className="max-h-40 text-sm overflow-y-auto">
          {/* Display loading if data is being fetched */}
          <span className="flex justify-center item-center">
            {loading ? <Loading /> : ""}
          </span>
          
          {/* Display check-in details */}
          {filteredData.map((item) => (
            <motion.tr key={item.id}>
              <td className="p-2 text-left">{item.user?.name}</td>
              <td className="text-left">{item.membership?.membershipType}</td>
              <td className="text-left font-semibold">
                {formatTimestamp(item.checkinTime)}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </motion.table>
    )}
      {/* <motion.table className="w-1/2 mt-10">
        <thead>
          <tr>
            <th className="py-2 text-left text-yellow-400">Client Name</th>
            <th className="py-2 text-left">Plan Type</th>
            <th className="py-2 text-left text-red-400">Time</th>
          </tr>
        </thead>
        <tbody className="max-h-40 text-sm overflow-y-auto">
          <span className="flex justify-center item-center">
            {loading ? <Loading /> : ""}
          </span>
          {filteredData.map((item) => (
            <motion.tr key={item.id}>
              <td className="p-2 text-left">{item.user?.name}</td>
              <td className="text-left">{item.membership?.membershipType}</td>
              <td className="text-left font-semibold">
                {formatTimestamp(item.checkinTime)}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </motion.table> */}
    </div>
  );
}

export default CheckIn;
