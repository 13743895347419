import React from 'react'

const Svg16 = () => {
  return (
    <div>
         <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="21"
                    width="21"
                  >
                    <path d="M20 10H4V4h16m0 11H4v-2h16m0-11H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2 4 2v-5h4c1.11 0 2-.89 2-2V4c0-1.11-.89-2-2-2z" />
                  </svg>
    </div>
  )
}

export default Svg16