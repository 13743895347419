import React, { useState } from 'react';


function TextForm({ label, value, onChange, placeHolder, secure,style }) {
  const [showPassword, setShowPassword] = useState(secure);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { borderColor = '', color = '' } = style || {};

  return (
    <div className="px-1">
      <p className={`block text-xs font-bold mb-2 mt-4 ${color}`}>
        {label}
      </p>
      <div className="relative">
        <input
          onChange={onChange}
          value={value}
          className={`w-full px-3 py-3 border rounded-md p-2 bg-transparent focus:outline-none focus:border-purple-600 ${borderColor}`}
          type={showPassword ? 'password' : 'text'}
          // id="email"
          placeholder={placeHolder}
        />
        {placeHolder === 'Enter your password' && (
          <div
            className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
               <svg
               xmlns="http://www.w3.org/2000/svg"
               x="0px"
               y="0px"
               width="20"
               height="20"
               viewBox="0 0 48 48"
               style={{ fill: '#ffffff' }}
               onClick={(e) => {
                 e.preventDefault();
                 setShowPassword(!showPassword);
               }}
             >
               <path d="M 25.144531 8 C 19.103964 8 13.561453 10.431954 9.2792969 14.439453 A 1.0001 1.0001 0 1 0 10.646484 15.898438 C 14.596328 12.201935 19.639099 10 25.144531 10 C 30.622398 10 35.641117 12.180148 39.582031 15.84375 A 1.0001 1.0001 0 1 0 40.943359 14.378906 C 36.670274 10.406508 31.154664 8 25.144531 8 z M 45.490234 20.9375 A 1.0001 1.0001 0 0 0 44.556641 21.625 C 40.545678 29.071912 33.337413 34 25.117188 34 C 16.915226 34 9.721572 29.091708 5.7050781 21.671875 A 1.0001 1.0001 0 0 0 3.7578125 22 L 3.7578125 22.048828 A 1.0001 1.0001 0 0 0 3.875 22.521484 C 5.2332071 25.057502 6.9512191 27.330506 8.9472656 29.257812 L 3.2695312 35.316406 A 1.0004426 1.0004426 0 0 0 4.7304688 36.683594 L 10.441406 30.587891 C 12.090811 31.948408 13.894282 33.09259 15.822266 33.96875 L 12.101562 41.560547 A 1.0001549 1.0001549 0 1 0 13.898438 42.439453 L 17.683594 34.712891 C 19.691189 35.420885 21.807993 35.849597 24.001953 35.958984 A 1.0001 1.0001 0 0 0 24 36 L 24 44 A 1.0001 1.0001 0 1 0 26 44 L 26 36 A 1.0001 1.0001 0 0 0 25.998047 35.966797 C 28.264048 35.87761 30.449568 35.441539 32.519531 34.716797 L 36.09375 42.419922 A 1.0001 1.0001 0 1 0 37.90625 41.580078 L 34.376953 33.96875 C 36.184396 33.152619 37.884395 32.108088 39.449219 30.863281 L 45.292969 36.707031 A 1.0001 1.0001 0 0 0 46.109375 37.009766 A 1.0001 1.0001 0 0 0 46.207031 36.996094 A 1.0001 1.0001 0 0 0 46.707031 35.292969 L 40.964844 29.550781 C 43.109605 27.548898 44.953216 25.160853 46.386719 22.470703 A 1.0001 1.0001 0 0 0 46.505859 22 L 46.505859 21.951172 A 1.0001 1.0001 0 0 0 45.490234 20.9375 z"></path>
             </svg>
         
            ) : (
              <svg
               xmlns="http://www.w3.org/2000/svg"
               x="0px"
               y="0px"
               width="20"
               height="20"
               viewBox="0 0 48 48"
               style={{ fill: '#ffffff' }}
               onClick={(e) => {
                 e.preventDefault();
                 setShowPassword(!showPassword);
               }}
             >
               <path d="M 23.986328 9 C 12.666705 9 2.6928719 16.845918 0.046875 27.126953 A 1.5002454 1.5002454 0 0 0 2.953125 27.873047 C 5.2331281 19.014082 14.065951 12 23.986328 12 C 33.906705 12 42.767507 19.01655 45.046875 27.873047 A 1.5002454 1.5002454 0 0 0 47.953125 27.126953 C 45.306493 16.84345 35.305951 9 23.986328 9 z M 24.001953 17 C 18.681885 17 14.337891 21.343999 14.337891 26.664062 C 14.337891 31.984127 18.681885 36.330078 24.001953 36.330078 C 29.322021 36.330078 33.667969 31.984126 33.667969 26.664062 C 33.667969 21.343999 29.322021 17 24.001953 17 z M 24.001953 20 C 27.700702 20 30.667969 22.965317 30.667969 26.664062 C 30.667969 30.36281 27.700702 33.330078 24.001953 33.330078 C 20.303205 33.330078 17.337891 30.362809 17.337891 26.664062 C 17.337891 22.965317 20.303205 20 24.001953 20 z"></path>
             </svg>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TextForm;
