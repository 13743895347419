import { motion } from 'framer-motion';
import React from 'react';



function Popup({ title, onClose, children }) {
  return (
    <motion.div 
    id='dropdown'
      initial={{ x: '100vw', opacity: 0 }} // Start from the right (100% of viewport width) and invisible
      animate={{ x: 0, opacity: 1 }} // Move to the initial position and fade in
      transition={{ duration: 0.2 }} // Set the duration of the animation
      className="fixed transition-transform duration-300  top-0 z-50 bottom-0 h-screen w-1/3 right-0 bg-neutral-800 bg-opacity-50 backdrop-blur-3xl overflow-auto"
    >
      <motion.div 

      className="popup-content p-8 max-h-screen">
        <div className="flex w-full justify-between">
          <button 
            onClick={onClose} 
        
          >
            <svg
              viewBox="0 0 512 512"
              fill="currentColor"
              height="24"
              width="24"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={48}
                d="M328 112L184 256l144 144"
              />
            </svg>
          </button>
          <p className="align-bottom font-semibold">{title}</p>
          <p></p>
        </div>
        {children}
      </motion.div>
    </motion.div>
  );
}

export default Popup;
