import React from 'react'
function Button({ title, onPress }) {
  return (
    <button

      onClick={onPress}
      className="w-full bg-purple-600 mt-4 text-white font-bold py-2 px-4 rounded-md hover:bg-yellow-300 hover:text-black"
      type="submit"
    >
      {title}
    </button>
  );
}

export default Button;
