import React, { useCallback, useEffect, useState } from 'react'
import { deleteMembership, getExpiredMember } from '../../api/Home/Membership';
// import MemberCard from '../../components/Card/MemberCard';
import Loading from '../../components/Loading/Loading';
import { motion } from 'framer-motion'
function ExpiredMembership() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem('Token')
  );
  const [member, setMember] = useState([]);
  // console.log(member);
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await getExpiredMember(token);
      setMember(response.members);
    } catch (error) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }

  const handleDeleteButton = async ({member}) => {
    try {
      const response = await deleteMembership(token, member);
      // console.log(response);
      if (response) {
        fetchData();
      }
    } catch (error) {
      setError("Error fetching member details");
    }
  }


  const MemberCard = ({ member, onClick }) => {
    return (
      // <Link to={`/Membership/${member.id}`}>
      <motion.div
        id="button"
        onClick={onClick} className="mt-10 bg-neutral-900 rounded">
        <div className="shadow-md rounded-lg overflow-hidden flex">
          <img
            src={"https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"}
            alt={member.name}
            className="h-full w-2/5 object-cover"
          />
          <div className="p-4 w-full flex flex-col justify-between">
            <div className="flex justify-between w-full">
              <p className="text">{member.name}</p>
              {/* <button onClick={async() => {
                    try {
                      const response = await deleteMembership(token, member.id);
                      // console.log(response);
                      if (response) {
                        fetchData();
                      }
                    } catch (error) {
                      setError("Error fetching member details");
                    }
              }}><svg width="24px" height="24px" viewBox="0 0 24 24" fill="fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.12817 8.15391C5.12817 10.4103 5.12817 13.5898 5.12817 15.1283C5.23074 16.4616 5.3333 18.2052 5.43587 19.436C5.53843 20.8719 6.7692 22.0001 8.2051 22.0001H15.7948C17.2307 22.0001 18.4615 20.8719 18.5641 19.436C18.6666 18.2052 18.7692 16.4616 18.8718 15.1283C18.9743 13.5898 18.8718 10.4103 18.8718 8.15391H5.12817Z" fill="red" />
                <path d="M19.1795 5.07698H16.6154L15.7949 3.53852C15.2821 2.61545 14.359 2.00006 13.3333 2.00006H10.8718C9.84615 2.00006 8.82051 2.61545 8.41026 3.53852L7.38462 5.07698H4.82051C4.41026 5.07698 4 5.48724 4 5.8975C4 6.30775 4.41026 6.71801 4.82051 6.71801H19.1795C19.5897 6.71801 20 6.41032 20 5.8975C20 5.38468 19.5897 5.07698 19.1795 5.07698ZM9.12821 5.07698L9.64103 4.25647C9.84615 3.84621 10.2564 3.53852 10.7692 3.53852H13.2308C13.7436 3.53852 14.1538 3.74365 14.359 4.25647L14.8718 5.07698H9.12821Z" fill="red" />
              </svg></button> */}
            </div>
            <div className="flex  justify-between">
              <div>
                <p className="text-xs text-gray-400">Plan Type</p>
                <p className="text-sm">{member.planName}</p>
              </div>
              <div>
                <p className="text-xs  text-gray-400">Plan Name</p>
                <p className="text-sm"> {member.packageName}</p>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <p className="text-xs text-gray-400">Start Date</p>
                <p className="text-xs"> {formatDate(member.startDate)}</p>
              </div>
              <div>
                <p className="text-xs text-red-400">Expiry</p>
                <p className="text-xs"> {formatDate(member.expireDate)}</p>
              </div>
              <div>
                <p className="text-xs text-gray-400">Plan Price</p>
                <p className="text-xs">Rs {member.price}/-</p>
              </div>
            </div>

          </div>
        </div>
      </motion.div>
      // </Link>
    );
  };

  return (
    <div>
      <p>Expired Membership</p>
      <div className="mt-10 flex w-full justify-between items-center">
        <div className="flex gap-4">
          <select className="bg-transparent text-sm rounded px-2 p-1 bg-#0A0A0A border focus:ring-purple-600 block ">
            <option>Flexible</option>
            <option>General</option>
          </select>
          <input
            className="bg-transparent px-4 bg-white bg-opacity-10 p-2 rounded"
            placeholder="Search by name"
          />
        </div>
      </div>
      <div className='container'>
        <motion.div

          className="grid grid-cols-3 gap-4">
          {loading && <Loading />}
          {member.map((member) => (
            <MemberCard key={member.id} member={member} />
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default ExpiredMembership
