import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCheckIn } from '../../api/Home/Membership';

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear() % 100}`;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

  return `${formattedDate}\  ${formattedTime}`;
}



function CheckInCard() {

  const [data, setData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('Token') || '');
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // Step 1: State for search query


  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getCheckIn(token);
      setData(response.checkIns);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const top4data = data.slice(-4);
  // // console.log(top4data);
  return (
    <div className="bg-zinc-300 bg-opacity-5 rounded-md backdrop-blur-2xl mt-3 p-5">
      <div className="flex justify-between">
        <p>Live Check-in</p>
        <Link to="/CheckIN">
          <p className="text-sm">View More</p>
        </Link>
      </div>
      {top4data.map((item) => (
        <div className="flex items-center gap-3 py-4 border-dashed	border-neutral-700 border-b-2">
          <img
            className="w-10 h-10"
            src="https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
          />
          <div>
            <p className="text-xs text-neutral-400">{formatTimestamp(item.checkinTime)}</p>
            <p className="text-sm">{item.user?.name}</p>
          </div>
        </div>
      ))}
      <div>
      </div>
    </div>
  );
}

export default CheckInCard;
