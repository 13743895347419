import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function formatDate(isoDate) {
  const date = new Date(isoDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

const MemberCard = ({ member, onClick }) => {
  return (
    // <Link to={`/Membership/${member.id}`}>
    <motion.div
      id="button"
      onClick={onClick}
      className="mt-10 bg-neutral-900 rounded"
    >
      <div className="shadow-md rounded-lg overflow-hidden flex">
        <img
          src={
            "https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
          }
          alt={member.name}
          className="h-full w-2/5 object-cover"
        />
        <div className="p-4 w-full flex flex-col justify-between">
          <div className="flex justify-between w-full">
            <p className="text">{member.name}</p>
          </div>
          <div className="flex  justify-between">
            <div>
              <p className="text-xs text-gray-400">Plan Type</p>
              <p className="text-sm">{member.planName}</p>
            </div>
            <div>
              <p className="text-xs  text-gray-400">Plan Name</p>
              <p className="text-sm"> {member.packageName}</p>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="text-xs text-gray-400">Start Date</p>
              <p className="text-xs"> {formatDate(member.startDate)}</p>
            </div>
            <div>
              <p className="text-xs text-red-400">Expiry</p>
              <p className="text-xs"> {formatDate(member.expireDate)}</p>
            </div>
            <div>
              <p className="text-xs text-gray-400">Plan Price</p>
              <p className="text-xs">Rs {member.price}/-</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
    // </Link>
  );
};

export default MemberCard;
