import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: 'https://wellverse.fit/v1/',
});

async function LeadsCall(token) {
  try {
    const response = await instance.get('/center/leads', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data; // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

async function createLeadsCall(token, customerName, mobileNumber, packageId, note, mode, status) {
  try {
    const response = await instance.post(
      '/center/leads',
      { customerName, mobileNumber, packageId, note, mode, status },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass the authorization token here
        },
      }
    );

    if (response.status === 200) {
      return response.data; // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

async function updateLeadsCall(token, status, id) {
  try {
    const response = await instance.patch(
      `/center/leads/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass the authorization token here
        },
      }
    );

    if (response.status === 200) {
      return response.data; // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}


export { LeadsCall, createLeadsCall , updateLeadsCall};
