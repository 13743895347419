import axios, { AxiosResponse } from 'axios';
import { assert } from 'console';

const instance = axios.create({
  baseURL: 'https://wellverse.fit/v1/',
});


async function PackageCall(token) {
    try {
      const response = await instance.get('/center/packages', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function createPackageCall(token, name, price, session,listOnMarketplace ) {
    console.log("LUUuS",listOnMarketplace);
    
    try {
      const response = await instance.post(
        '/center/createPackage',
        {
          "name":name,
          "price":price,
          "session":session,
          "validity":session,
          "details":name,
          "package_type":"general",
          "isListed":true
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass the authorization token here
          },
        }
      );

      if (response) {
        return response; // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      console.log(error);
    }
  }
// eslint-disable-next-line no-unused-vars
  async function editPackageCall(token, id, package_name, packagePrice, packageDuration,listOnMarketplace ) {
    try {
      const response = await instance.put(
        `/center/packages/${id}`,
        {
          "name":package_name,
          "price":packagePrice,
          // "session":packageDuration,
          "validity":packageDuration,
          // "details":name,
          // "package_type":"general"
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass the authorization token here
          },
        }
      );

      if (response) {
        return response; // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deletePackageCall(token, id, ) {
    try {
      const response = await instance.delete(
        `/center/packages/${id}`,

        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass the authorization token here
          },
        }
      );

      if (response) {
        return response; // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      console.log(error);
    }
  }



  export {PackageCall, createPackageCall,editPackageCall,deletePackageCall}
