import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import { getCenterDetails } from '../../api/Root';
import Svg7 from '../../../svg/Svg7';
import Svg8 from '../../../svg/Svg8';

function EditListingCard() {
  const [data, setData] = useState([]);
  const [token, setToken] = useState(
    localStorage.getItem('Token')
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await getCenterDetails(token);
      setData(response);
    } catch (error) {
    } finally {
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div>
      <div className="p-2 bg-purple-400 rounded flex items-center justify-between relative">
        <p>Edit Listing</p>
        <Link to="/Main/Listing">
       <Svg7 />
        </Link>
      </div>
      <div className="relative">
        <img
          src={data.details?.images}
          className="w-full h-48 object-cover rounded mt-2"
        />
        <div className="bg-black absolute bottom-3 left-3 right-3 opacity-50 py-1 rounded p-2 px-3">
          <p className="text-xm font-black">{data.details?.name}</p>
          <div className="flex items-center gap-1">
            <Svg8/>
            <p className="listing w-4/5">{data.details?.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditListingCard;
