import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

function DashboardLayout() {

  return (
    <div className="w-screen h-screen">
      <div className="flex h-screen overflow-hidden">
        <Sidebar/>
        <div className="relative bg-#0a0a0a flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Navbar />
          <main style={{ height: '100%' }}>
            <div className="px-10">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
