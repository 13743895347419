import React from 'react'

const Svg17 = () => {
  return (
    <div>
        <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="21"
                    width="21"
                  >
                    <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.767L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.233V16H4V4h16v12z" />
                    <path d="M17.207 7.207l-1.414-1.414L11 10.586 8.707 8.293 7.293 9.707 11 13.414z" />
                  </svg>
    </div>
  )
}

export default Svg17