import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: 'https://wellverse.fit/v1/',
});

// const instance = axios.create({
//   baseURL: 'http://localhost:8000/v1/',
// });

// interface LoginResponse {
//   token: string; // Update the type based on your actual response structure
//   onBoarded: boolean; // Add other fields if your response contains more data
// }

const LoginCall = async (email, password) => {
  try {
    const response = await instance.post(
      '/auth/loginCenter',
      {
        email,
        password,
      }
    );

    if (response.status === 200) {
      return response.data; // Return the response data, which may include an authentication token
    }
    throw new Error('Login failed');
  } catch (error) {
    throw new Error('An error occurred during login');
  }
};

// interface CenterDetails {
//   name: string;
//   description: string;
//   street: string;
//   city: string;
//   state: string;
//   postalCode: string;
//   country: string;
//   type: string;
//   coordinates: number[];
// }

const CenterDetailsCall = async (  centerDetails,token) => {
  try {
    const response = await instance.post(
      '/center/centerDetails',
      centerDetails,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass the authorization token here
        },
      }
    );

    if (response) {
      return response;
    }
    throw new Error('Login failed');
  } catch (error) {
    throw new Error('Some thing went wrong');
  }
};

const centerUploadImageCall = async () => {

}

const centerAmenitiesEquipmentsCall = async (
  selectedAmities,
  selectedEquipments,
  token
) => {
  try {
    const response = await instance.post(
      '/center/AmenitiesEquipment',
      { selectedAmities, selectedEquipments },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass the authorization token here
        },
      }
    );

    if (response) {
      return response;
    }
    throw new Error('Login failed');
  } catch (error) {
    throw new Error('Some thing went wrong');
  }
};

export { LoginCall, CenterDetailsCall, centerAmenitiesEquipmentsCall };
