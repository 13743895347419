import axios from 'axios';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CenterDetailsCall } from '../../api/Authentication/index';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Loading from '../../components/Loading/Loading';
import TextForm from '../../components/TextInput/TextForm';
import useTimeout from '../../hooks/useTimeout';
import OnboardingLayout from '../../layout/Onboarding/OnboardingLayout';
import { validateInput } from '../../utils/Validator';

function CenterDetails() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); // Add state for error message
  const [token, setToken] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);

  const fileInputRef = useRef(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('Token');

    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const resetErrorTimeout = useTimeout(() => {
    setError(''); // Clear the error message
  }, 5000); // 5000 milliseconds (5 seconds)

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [state, setState] = useState('');

  const handleImageChange = (e) => {
    const { files } = e.target;
  
    if (files && files.length > 0) {
      if (
        selectedImages.length + files.length >= 3 &&
        selectedImages.length + files.length <= 8
      ) {
        setSelectedImages([...selectedImages, ...files]);
      } else {
        setError(`You can select between 3 and 8 images only.`);
        resetErrorTimeout();
      }
    }
  };
  

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const deleteImage = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };


  const handleOnPressConfirm = async () => {
    const fieldsToValidate = [
      { field: name, message: 'name' },
      { field: description, message: 'about' },
      { field: street, message: 'address' },
      { field: city, message: 'city' },
      { field: postalCode, message: 'postal code' },
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const { field, message } of fieldsToValidate) {
      if (!validateInput(field)) {
        setError(`Please double-check your ${message} and try again.`);
        resetErrorTimeout();
        return;
      }
    }
    setLoading(true);

    try {
      const formData = new FormData();
      selectedImages.forEach((image,index) => {
        console.log('Appending image to formData:', image, ); // Add this line to check if images are being appended
        formData.append('images', image, );
      });
      console.log('FormData:', formData); // Add this line to check the FormData object
      
      const imageResponse = await axios.post('https://wellverse.fit/v1/center/uploadImages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(imageResponse);

      const response = await CenterDetailsCall(
        {
          name,
          description,
          street,
          city,
          postalCode,
          state,
          country: 'IN',
          type: 'Point',
          coordinates: [12, 31],
        },
        token
      );
      setError(`${response.status}`);
      resetErrorTimeout();
    } catch (err) {
      setError(`${err}`);
      resetErrorTimeout();
    } finally {
      navigate('/onboarding/Amenities');
    }
    setLoading(false);
  };

  return (
    <div>
      <OnboardingLayout>
        {loading ? <Loading /> : null}
        {error && <ErrorMessage message={error} />}
        <div className="flex p-20 w-full">
          <div className="w-1/2">
            <h1 className="text-xl">Center Details</h1>
            <div className="w-2/3 mt-10">
              <TextForm
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Center Name"
                placeHolder="Enter your center name"
              />
              <TextForm
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label="About"
                placeHolder="Enter about your center"
              />
              <h1 className="text-xl mt-10">Center Location</h1>
              <TextForm
                label="Address"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeHolder="Enter center address"
              />
              <div className="flex">
                <TextForm
                  label="City"
                  placeHolder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextForm
                  label="Postal Code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeHolder="Postal Code"
                />
              </div>
              <div className="flex items-center">
                <div className="mb-4 mt-2 ml-2 w-full">
                  <p className="block text-gray-700 font-bold text-xs mb-2">
                    State
                  </p>
                  <select
                    id="countries"
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    className="text-sm rounded-lg p-4 bg-#0A0A0A bg-transparent border focus:ring-purple-600 block w-full dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-600 border-gray-600"
                  >
                    <option selected>Choose a state</option>
                    <option selected>Choose a state</option>
                    <option value="AP">Andhra Pradesh</option>
                    <option value="AR">Arunachal Pradesh</option>
                    <option value="AS">Assam</option>
                    <option value="BR">Bihar</option>
                    <option value="CG">Chhattisgarh</option>
                    <option value="GA">Goa</option>
                    <option value="GJ">Gujarat</option>
                    <option value="HR">Haryana</option>
                    <option value="HP">Himachal Pradesh</option>
                    <option value="JK">Jammu and Kashmir</option>
                    <option value="JH">Jharkhand</option>
                    <option value="KA">Karnataka</option>
                    <option value="KL">Kerala</option>
                    <option value="MP">Madhya Pradesh</option>
                    <option value="MH">Maharashtra</option>
                    <option value="MN">Manipur</option>
                    <option value="ML">Meghalaya</option>
                    <option value="MZ">Mizoram</option>
                    <option value="NL">Nagaland</option>
                    <option value="OD">Odisha</option>
                    <option value="PB">Punjab</option>
                    <option value="RJ">Rajasthan</option>
                    <option value="SK">Sikkim</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="TS">Telangana</option>
                    <option value="TR">Tripura</option>
                    <option value="UK">Uttarakhand</option>
                    <option value="UP">Uttar Pradesh</option>
                    <option value="WB">West Bengal</option>
                  </select>
                </div>
                {/* <div className="mt-4 py-3 border border-gray-600 rounded-md p-2 bg-transparent focus:outline-none focus:border-purple-600 ml-2 w-full">
                  <button onClick={handleOnPressGetCoordinate} type="button">
                    <p>Get Coordinates</p>
                  </button>
                </div> */}
              </div>
              <Button title="Confirm" onPress={handleOnPressConfirm} />
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="text-xl mb-10">Center Images </h1>
            <p>Please upload minimum of 3 images and maximum of 8 Images</p>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              multiple
              onChange={handleImageChange}
              className="mb-4 hidden"
            />
            <button
              type="submit"
              onClick={openFileSelector}
              className="h-1/5 w-1/4 border border-dashed text-white font-bold py-2 px-4 rounded hover:bg-slate-500"
            >
              <p>+</p>
            </button>
            <div className="grid grid-cols-4 mt-10 gap-4">
            {selectedImages.map((image, index) => (
  <div key={index} className="relative">
    <button
      type="submit"
      onClick={() => deleteImage(index)}
      className="absolute top-2 right-2 text-white p-1 rounded-full"
    >
      X
    </button>
    <div className="max-w-md h-45 w-45 border-violet-500 border-4 rounded-lg">
      <img
        src={URL.createObjectURL(image)}
        alt={`Selected ${index}`}
        className="object-fill h-48 w-48"
      />
    </div>
  </div>
))}
            </div>
          </div>
        </div>
      </OnboardingLayout>
    </div>
  );
}

export default CenterDetails;
