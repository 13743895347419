import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import TextForm from '../../components/TextInput/TextForm';
import OnboardingLayout from '../../layout/Onboarding/OnboardingLayout';
import { useNavigate } from 'react-router-dom';

function Popup({ onClose }) {
  const [schedule, setSchedule] = useState({
    Monday: { morning: '', evening: '' },
    Tuesday: { morning: '', evening: '' },
    Wednesday: { morning: '', evening: '' },
    Thursday: { morning: '', evening: '' },
    Friday: { morning: '', evening: '' },
    Saturday: { morning: '', evening: '' },
    Sunday: { morning: '', evening: '' },
  });

  // Function to handle input changes
  const handleInputChange = (day, period, value) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [period]: value,
      },
    }));
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 bg-stone-950 flex items-center justify-center z-50">
      <div className="bg-stone-900 relative p-4  rounded-lg shadow-lg">
        <button
          type="button"
          className="absolute top-0 right-0 m-2 text-2xl text-white"
          onClick={onClose}
        >
          X
        </button>
        <h2 className="font-semibold text-white mb-4">Add Center Timing</h2>
        <div>
          <p className="text-xs -mb-2 px-2 text-white">Timing Name</p>
          <TextForm placeHolder="Enter timing title" />
          <div>
            <table className="mt-4 text-xs">
              <thead>
                <tr>
                  <th className="text-left">Day</th>
                  <th className="text-center">Morning</th>
                  <th className="text-center">Evening</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(schedule).map((day) => (
                  <tr key={day}>
                    <td className="text-left">{day}</td>
                    <td className="py-1 px-1 w-1/3">
                      <input
                        type="text"
                        placeholder="7AM"
                        value={schedule[day].morning}
                        className="w-full px-2 mr-2 py-2 border border-gray-600 rounded-md p-2 bg-transparent focus:outline-none focus:border-purple-600"
                        onChange={(e) =>
                          handleInputChange(day, 'morning', e.target.value)
                        }
                      />
                    </td>
                    <td className="w-1/3">
                      <input
                        type="text"
                        placeholder="10PM"
                        value={schedule[day].evening}
                        onChange={(e) =>
                          handleInputChange(day, 'evening', e.target.value)
                        }
                        className="w-full px-2 mr-2 py-2 border border-gray-600 rounded-md p-2 bg-transparent focus:outline-none focus:border-purple-600"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Button title="Add Timing" />
      </div>
    </div>
  );
}

function Timing() {
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleConfimClick = () => {
    navigate('/home');
  };

  return (
    <div>
      <OnboardingLayout>
        {isPopupOpen && <Popup onClose={closePopup} />}
        <div className="p-20 w-1/2">
          <p className="text-xl mb-10">Center Timings</p>
          <div className="flex justify-between">
            <p>Add center timing</p>
            <button
              onClick={openPopup}
              type="button"
              className="bg-orange-600 p-2 rounded-lg"
            >
              <p className="text-sm font-semibold"> + Add Timing</p>
            </button>
          </div>
          <div className="w-1/3 mt-10">
            <Button onPress={handleConfimClick} title="Confirm" />
          </div>
        </div>
      </OnboardingLayout>
    </div>
  );
}

export default Timing;
