import React, { useEffect, useState } from "react";
import { couponCall, createCouponCodeCall } from "../../api/Home/Coupons";
import Button from "../../components/Button/Button";
import Popup from "../../components/Popup/Popup";
import TextForm from "../../components/TextInput/TextForm";
import Svg9 from "../../../svg/Svg9";
import {
  validateCenterName,
  validateName,
  validatePackageDuration,
} from "../../services/validation";

function GrowthTool() {
  const [isCouponsPopupVisible, setCouponsPopupVisible] = useState(false);
  const [isCreateCouponPopupVisible, setIsCreateCouponPopupVisible] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token] = useState(localStorage.getItem("Token"));
  const [coupon, setCoupon] = useState([]);

  const [code, setcode] = useState();
  const [discountAmount, setDiscountAmount] = useState();
  const [minPurchaseValue, setMinPurchaseValue] = useState();
  const [minUsageLimit, setMinUsageLimit] = useState();
  const [couponCodeValid, setCouponCodeValid] = useState(true);
  const [discountAmountValid, setDiscountAmountValid] = useState(true);
  const [minPurchaseValueValid, setMinPurchaseValueValid] = useState(true);
  const [couponDisplayStates, setCouponDisplayStates] = useState({});

  //for toggle state logic
  useEffect(() => {
    // Set initial toggle states for coupons when fetching data
    const initialStates = coupon.reduce((acc, curr) => {
      acc[curr._id] = curr.displayInListings || false;
      return acc;
    }, {});
    setCouponDisplayStates(initialStates);
  }, [coupon]);

  // Function to handle the toggle change for each coupon
  const handleToggle = (e, couponId) => {
    const isChecked = e.target.checked;
    setCouponDisplayStates((prevState) => ({
      ...prevState,
      [couponId]: isChecked,
    }));

    // Logic to update the backend with the display status
    // You can add the API call here to update the display status for the coupon with ID: couponId
  };

  const fetchCouponCodes = async () => {
    setLoading(true);
    try {
      const response = await couponCall(token);
      setCoupon(response);
    } catch (error) {
      setError("Error fetching data: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isCouponsPopupVisible) {
      fetchCouponCodes();
    }
  }, [isCouponsPopupVisible]);

  const handlePopupClose = () => {
    setCouponsPopupVisible(false);
  };

  const openCouponPopup = () => {
    setCouponsPopupVisible(true);
    // console.log("openCouponPopup");
  };

  const openCreateCouponPopup = () => {
    setIsCreateCouponPopupVisible(true);
  };

  const closeCreateCouponPopup = () => {
    setIsCreateCouponPopupVisible(false);
  };

  const createCouponCodeButton = async () => {
    setLoading(true);
    setError(null);

    const isCouponCodeValid = validateCenterName(code);
    setCouponCodeValid(isCouponCodeValid);
    const isDiscountAmountValid = validatePackageDuration(discountAmount);
    setDiscountAmountValid(isDiscountAmountValid);

    try {
      // if(isCouponCodeValid && isDiscountAmountValid){
      //   const response = await createCouponCodeCall(token, code, discountAmount,minPurchaseValue );
      //   setIsCreateCouponPopupVisible(false); // Close the create coupon popup
      // }
      const response = await createCouponCodeCall(
        token,
        code,
        discountAmount,
        minPurchaseValue
      );

      if(response)
      setIsCreateCouponPopupVisible(false); // Close the create coupon popup
    } catch (error) {
      setError("Error creating coupon: " + error.message);
    } finally {
      setLoading(false);
      setIsCreateCouponPopupVisible(false);
      setCouponsPopupVisible(true);
      setLoading(false)
      setcode("");
      setMinPurchaseValue("");
      setDiscountAmount("");
    }
  };

  const couponCodeStyle = {
    borderColor: couponCodeValid ? "" : "border-red-500",
    color: couponCodeValid ? "" : "text-red-500",
  };
  const DiscountAmountStyle = {
    borderColor: couponCodeValid ? "" : "border-red-500",
    color: couponCodeValid ? "" : "text-red-500",
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const isDropdown =
        e.target instanceof Element && e.target.closest(`#dropdown`);

      const isButton =
        e.target instanceof Element && e.target.closest("#viewCouponsButton");
      const isButton1 =
        e.target instanceof Element && e.target.closest("#createCouponsButton");

      if (
        !isDropdown &&
        !isButton &&
        !isButton1 &&
        (isCouponsPopupVisible || isCreateCouponPopupVisible)
      ) {
        setCouponsPopupVisible(false);
        setIsCreateCouponPopupVisible(false);
      }
    };
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [
    isCouponsPopupVisible,
    isCreateCouponPopupVisible,
    setCouponsPopupVisible,
    setIsCreateCouponPopupVisible,
  ]);
  return (
    <div className={`mt-5 `}>
      {isCouponsPopupVisible && (
        <Popup title="All Coupons" onClose={handlePopupClose}>
          <div className="mt-10" />
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : coupon.length === 0 ? (
            <div>No coupons available. Click below to create coupons.</div>
          ) : (
            coupon.map((couponItem) => (
              <div
                className="p-4 border border-dashed my-5 rounded"
                key={couponItem._id}
              >
                <div className="flex justify-between">
                  <p className="text-xl">{couponItem.code}</p>
                  <div className="flex flex-col">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        // checked={couponItem.displayInListings}
                        // onChange={(e) => handleToggle(e, couponItem._id)}
                        checked={couponDisplayStates[couponItem._id]}
                        onChange={(e) => handleToggle(e, couponItem._id)}
                        className="form-checkbox h-4 w-4 text-purple-600"
                      />
                      <span className="ml-2 text-xs">Display in Listings</span>
                    </label>
                    <button className="text-red-400 text-right">DELETE</button>
                  </div>
                </div>
                <div className="flex justify-between mt-5">
                  <div>
                    <p className="text-xs">Discount Amount</p>
                    <p>{couponItem.discountAmount}</p>
                  </div>
                  <div>
                    <p className="text-xs">Min. Purchase Value</p>
                    <p>{couponItem.minPurchaseValue}</p>
                  </div>
                  <div>
                    <p className="text-xs">Min Usage Limit</p>
                    <p>{couponItem.minUsageLimit}</p>
                  </div>
                </div>

                {/* <p>Discount Amount: {couponItem.discountAmount}</p>
              <p>Min Purchase Value: {couponItem.minPurchaseValue}</p>
              <p>Min Usage Limit: {couponItem.minUsageLimit}</p> */}
              </div>
            ))
          )}
        </Popup>
      )}
      {isCreateCouponPopupVisible && (
        <Popup title="Create Coupon" onClose={closeCreateCouponPopup}>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <div className="mt-20">
              <TextForm
                label="Coupon Name"
                value={code}
                onChange={(e) => setcode(e.target.value)}
                placeHolder="Ex. FIT200"
                style={couponCodeStyle}
              />
              {!couponCodeValid && (
                  <p className="text-red-500 ml-1 mt-2 text-xs">
                    Coupon Code should be alphanumeric and should contain atleast 3
                    characters
                  </p>
                )}
              <div className="flex mb-10">
                <TextForm
                  label="Discount Amount"
                  value={discountAmount}
                  onChange={(e) => setDiscountAmount(e.target.value)}
                  placeholder="100"
                />
                {!discountAmountValid && (
                  <p className="text-red-500 ml-1 mt-2 text-xs">
                    Invalid Duration Amount
                  </p>
                )}
                <TextForm
                  label="Minimum Purchase Value"
                  name="minPurchaseValue"
                  value={minPurchaseValue}
                  onChange={(e) => setMinPurchaseValue(e.target.value)}
                  placeholder="Ex. FIT200"
                />
              </div>
              <Button onPress={createCouponCodeButton} title="Create Coupon" />
            </div>
          )}
        </Popup>
      )}

      <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <Svg9 />
          <p className="ml-2">Growth Tools</p>
        </div>
        {/* <p className="font-semibold text-purple-400">Coming Soon</p> */}
      </div>
      <div
        className={`mt-10 grid grid-cols-3 grid-rows-2 ${
          isCreateCouponPopupVisible
            ? "opacity-25 transition-all duration-300"
            : "opacity-100 ease-in-out transition-all duration-500"
        } gap-4 `}
      >
        <div className="bg-neutral-900 p-5 py-7 rounded-lg">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Drip Campaign</p>
            <p className="text-yellow-600 text-xs font-semibold bg-stone-800 p-2 rounded-lg">
              Coming Soon
            </p>
          </div>
          <p className="text-sm mt-5 w-3/4">
            Target potential clients based on their behavior using automated
            notifications, SMS, Emails.
          </p>
        </div>
        <div className="bg-neutral-900 p-5 py-7 rounded-lg">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Free Trial</p>
            <p className="text-yellow-600 text-xs font-semibold bg-stone-800 p-2 rounded-lg">
              Coming Soon
            </p>
          </div>
          <p className="text-sm mt-5 w-3/4">
            Give 2 Free Trial To First Time Users And Attract More Audience
            Through Fitverse
          </p>
        </div>
        <div className="bg-neutral-900 p-5 py-7 rounded">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Coupon</p>
            <div className="">
              <button
                type="button"
                id="viewCouponsButton"
                onClick={openCouponPopup}
                className="border-purple-400 border mr-2 text-xs font-semibold p-2 rounded-lg"
              >
                View Coupons
              </button>
              <button
                type="button"
                id="createCouponsButton"
                onClick={openCreateCouponPopup}
              >
                <p className="bg-purple-400 text-xs font-semibold p-2 rounded-lg">
                  Create Coupons
                </p>
              </button>
            </div>
          </div>
          <p className="text-sm mt-5 w-3/4">
            Incentivize purchases, attract new customers, and foster loyalty by
            providing special offers.
          </p>
        </div>
        <div className="bg-neutral-900 p-5 py-7 rounded-lg">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Smart Pricing</p>
            <p className="text-yellow-600 text-xs font-semibold bg-stone-800 p-2 rounded-lg">
              Coming Soon
            </p>
          </div>
          <p className="text-sm mt-5 w-3/4">
            Incentivize purchases, attract new customers, and foster loyalty by
            providing special offers.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GrowthTool;
