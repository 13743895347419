import axios, { AxiosResponse } from 'axios';
import { assert } from 'console';

const instance = axios.create({
  baseURL: 'https://wellverse.fit/v1/',
});

  async function createCouponCodeCall(token, code, discountAmount, minPurchaseValue, minUsageLimit ) {
    // // console.log(code,discountAmount,minPurchaseValue)
    try {
      const response = await instance.post(
        '/center/coupon',
        {
            "code":code,
            "discountType":"flat",
            "discountAmount": discountAmount,
            "minPurchaseValue": minPurchaseValue,
            "minUsageLimit": minUsageLimit
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass the authorization token here
          },
        }
      );

      if (response) {
        return response; // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      // console.log(error);
    }
  }

  async function couponCall(token) {
    try {
      const response = await instance.get('/center/coupon', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      // console.log(error);
    }
  }

export {createCouponCodeCall,couponCall}
