import React, { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// import Svg12 from '../../../svg/Svg12';
import Svg13 from '../../../svg/Svg13';
import Svg14 from '../../../svg/Svg14';
import Svg15 from '../../../svg/Svg15';
import Svg16 from '../../../svg/Svg16';
import Svg17 from '../../../svg/Svg17';
import { motion } from 'framer-motion';

function Sidebar() {
  const location = useLocation();
  const { pathname } = location;
  const [hoveredItem, setHoveredItem] = useState('');

  const handleHover = (item) => {
    setHoveredItem(item);
  };

  const handleHoverGo = () => {
    setHoveredItem('');
  };

  return (
    <aside
      className={`pt-14 flex h-screen flex-col overflow-y-hidden border-r-2 border-stone-900 bg-#0a0a0a duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0`}
    >
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
          <div>
            <ul className="mb-6 flex flex-col gap-3">
             
              <li>
                <NavLink
                  to="/Home"
                  // className={`relative flex items-center rounded-sm py-2 px-2 font-medium duration-300 ease-in-out ${
                  //   pathname.includes('Home') && hoveredItem === 'Home' ? 'text-purple-400' : ''
                  // }`}
                  className={`relative flex items-center rounded-sm py-2 px-2 font-medium duration-300 ease-in-out ${pathname.includes('Home') ? 'text-purple-400' : ''}`}
                  onMouseEnter={() => handleHover('Home')}
                  onMouseLeave={handleHoverGo}
                >
                  <Svg13 />
                  {hoveredItem === 'Home' && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className="absolute bottom-8 -right-1 bg-white text-black p-1 rounded text-xs"
                    >
                      Home
                    </motion.div>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/CheckIN"
                  className={`relative flex items-center rounded-sm py-2 px-2 font-medium duration-300 ease-in-out ${pathname.includes('CheckIN') ? 'text-purple-400' : ''}`}

                  onMouseEnter={() => handleHover('CheckIN')}
                  onMouseLeave={handleHoverGo}
                >
                  <Svg14 />
                  {hoveredItem === 'CheckIN' && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className="absolute bottom-8 -right-2 bg-white text-black p-1 rounded text-xs whitespace-nowrap"
                    >
                      Check In
                    </motion.div>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/GrowthTool"
                  className={`relative flex items-center rounded-sm py-2 px-2 font-medium duration-300 ease-in-out ${pathname.includes('GrowthTool') ? 'text-purple-400' : ''}`}
                  onMouseEnter={() => handleHover('GrowthTool')}
                  onMouseLeave={handleHoverGo}
                >
                  <Svg15 />
                  {hoveredItem === 'GrowthTool' && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className="absolute bottom-8 -right-6 bg-white text-black p-1 rounded text-xs whitespace-nowrap"
                    >
                      Growth Tools
                    </motion.div>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Membership"
                  className={`relative flex items-center rounded-sm py-2 px-2 font-medium duration-300 ease-in-out ${pathname.includes('Membership') ? 'text-purple-400' : ''}`}
                  onMouseEnter={() => handleHover('Membership')}
                  onMouseLeave={handleHoverGo}
                >
                  <Svg16 />
                  {hoveredItem === 'Membership' && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className="absolute bottom-8 -right-6 bg-white text-black p-1 rounded text-xs whitespace-nowrap"
                    >
                      Membership
                    </motion.div>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Package"
                  className={`relative flex items-center rounded-sm py-2 px-2 font-medium duration-300 ease-in-out ${pathname.includes('Package') ? 'text-purple-400' : ''}`}
                  onMouseEnter={() => handleHover('Package')}
                  onMouseLeave={handleHoverGo}
                >
                  <Svg17 />
                  {hoveredItem === 'Package' && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className="absolute bottom-8 -right-2 bg-white text-black p-1 rounded text-xs whitespace-nowrap"
                    >
                      Package
                    </motion.div>
                  )}
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
}


export default Sidebar;
