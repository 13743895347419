import React, { useState } from "react";
import Button from "../../components/Button/Button";
import TextForm from "../../components/TextInput/TextForm";
import { motion } from "framer-motion";
import {
  validateCenterName,
  validateCenterDescription,
  validateCenterStreet,
  validatePostalCode,
} from "../../services/validation";
function CenterDetailsListing() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");

  const [nameValid, setNameValid] = useState(true);
  const [descriptionValid, setDescriptionValid] = useState(true);
  const [streetValid, setStreetValid] = useState(true);
  const [postalCodeValid, setPostalCodeValid] = useState(true);

  function handleSubmit() {
    const isCenterNameValid = validateCenterName(name);
    setNameValid(isCenterNameValid);

    const isDescriptionValid = validateCenterDescription(description);
    setDescriptionValid(isDescriptionValid);

    const isStreetValid = validateCenterStreet(street);
    setStreetValid(isStreetValid);

    const isPostalCodeValid = validatePostalCode(postalCode);
    setPostalCodeValid(isPostalCodeValid);
  }

  const nameStyle = {
    borderColor: nameValid ? "" : "border-red-500",
    color: nameValid ? "" : "text-red-500",
  };
  const descStyle = {
    borderColor: descriptionValid ? "" : "border-red-500",
    color: descriptionValid ? "" : "text-red-500",
  };
  const streetStyle = {
    borderColor: streetValid ? "" : "border-red-500",
    color: streetValid ? "" : "text-red-500",
  };
  const postalCodeStyle = {
    borderColor: postalCodeValid ? "" : "border-red-500",
    color: postalCodeValid ? "" : "text-red-500",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }} // Set the initial opacity to 0
      animate={{ opacity: 1 }} // Animate to opacity 1
      transition={{ duration: 0.5, ease: "easeInOut" }} // Set the duration and easing of the animation
      className="flex w-full"
    >
      <div className="w-1/2">
        <div className="w-2/3">
          
          <TextForm
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Center Name"
            placeHolder="Enter your center name"
            style={nameStyle}
          />
          {!nameValid && (
            <p className="text-red-500 ml-1 mt-2 text-xs">
              Center Name must be alphanumeric and must contain atleast 3
              characters
            </p>
          )}

          
          <TextForm
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="About"
            placeHolder="Enter about your center"
            style={descStyle}
          />
          {!descriptionValid && (
            <p className="text-red-500 ml-1 mt-2 text-xs">
              Description must contain atleast 20 characters
            </p>
          )}
          <h1 className="text-xl mt-10">Center Location</h1>
          
          <TextForm
            label="Address"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeHolder="Enter center address"
            style={streetStyle}
          />
          {!streetValid && (
            <p className="text-red-500 ml-1 mt-2 text-xs">
              Address must contain atleast 15 characters
            </p>
          )}
          <div className="flex flex-col">
            
            <div className="flex">
              <TextForm
                label="City"
                placeHolder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />

              <TextForm
                label="Postal Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeHolder="Postal Code"
                style={postalCodeStyle}
              />
            </div>
            <div>
              {!postalCodeValid && (
                <p className="text-red-500 ml-1 mt-2 text-xs">
                  Postal Code must contain 6 characters
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="mb-4 mt-2 ml-2 w-full">
              <p className="block text-gray-700 font-bold text-xs mb-2">
                State
              </p>
              <select
                id="countries"
                onChange={(e) => setState(e.target.value)}
                value={state}
                className="text-sm rounded-lg p-4 bg-#0A0A0A bg-transparent border focus:ring-purple-600 block w-full dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-600 border-gray-600"
              >
                <option selected>Choose a state</option>
                <option selected>Choose a state</option>
                <option value="AP">Andhra Pradesh</option>
                <option value="AR">Arunachal Pradesh</option>
                <option value="AS">Assam</option>
                <option value="BR">Bihar</option>
                <option value="CG">Chhattisgarh</option>
                <option value="GA">Goa</option>
                <option value="GJ">Gujarat</option>
                <option value="HR">Haryana</option>
                <option value="HP">Himachal Pradesh</option>
                <option value="JK">Jammu and Kashmir</option>
                <option value="JH">Jharkhand</option>
                <option value="KA">Karnataka</option>
                <option value="KL">Kerala</option>
                <option value="MP">Madhya Pradesh</option>
                <option value="MH">Maharashtra</option>
                <option value="MN">Manipur</option>
                <option value="ML">Meghalaya</option>
                <option value="MZ">Mizoram</option>
                <option value="NL">Nagaland</option>
                <option value="OD">Odisha</option>
                <option value="PB">Punjab</option>
                <option value="RJ">Rajasthan</option>
                <option value="SK">Sikkim</option>
                <option value="TN">Tamil Nadu</option>
                <option value="TS">Telangana</option>
                <option value="TR">Tripura</option>
                <option value="UK">Uttarakhand</option>
                <option value="UP">Uttar Pradesh</option>
                <option value="WB">West Bengal</option>
              </select>
            </div>
            {/* <div className="mt-4 py-3 border border-gray-600 rounded-md p-2 bg-transparent focus:outline-none focus:border-purple-600 ml-2 w-full">
            <button onClick={handleOnPressGetCoordinate} type="button">
              <p>Get Coordinates</p>
            </button>
          </div> */}
          </div>
          <Button title="Confirm" onPress={handleSubmit} />
        </div>
      </div>
    </motion.div>
  );
}

export default CenterDetailsListing;
