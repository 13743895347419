export const validateName = (name) => {
  const isValid = name.trim().length >= 3; // Minimum length check
  // setNameValid(isValid);
  return isValid;
};

export const validatePhoneNumber = (phoneNumber) => {
  const isValid = /^\+?\d{10}$/.test(phoneNumber); // Validation for exactly 10 digits
  return isValid;
};

export const validateEmail = (email) => {
  const isValid = /\S+@\S+\.\S+/.test(email);
  return isValid;
};

export const validateCenterName = (centerName) => {
  const alphanumericRegex = /^[a-zA-Z0-9\s]{3,}$/;
  return alphanumericRegex.test(centerName);
};

export const validateCenterDescription = (centerName) => {
    const minLength = 20;
    return centerName.trim().length >= minLength;
};

export const validateCenterStreet = (centerName) => {
    const minLength = 15;
    return centerName.trim().length >= minLength;
};

export const validatePostalCode = (postalCode) => {
    const postalCodeRegex = /^\d{6}$/;
    return postalCodeRegex.test(postalCode);
};

export const validatePackageDuration = (duration) => {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(duration);
  };

  export const validatePackagePrice = (price) => {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(price);
  };  


  export const validateLeadName = (leadName) => {
    const alphanumericRegex = /^[a-zA-Z0-9\s]{3,}$/;
    return alphanumericRegex.test(leadName);
  };
