import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Svg4 from '../../../svg/Svg4';

function MainCardTwo() {
  const [columnData] = useState({
    series: [
      {
        name: 'Inflation',
        data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },

      xaxis: {
        categories: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
        position: 'top',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      title: {
        text: 'Monthly Inflation in Argentina, 2002',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444',
        },
      },
    },
  });
  const [chartData] = useState({
    series: [
      {
        name: 'Monthly Income',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 150,
        type: 'curve',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: '',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['transparent'],
          opacity: 0.1,
        },
      },
      menu: {
        show: false, // Hide the hamburger menu
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
  });

  const [options] = useState({
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    },
  });

  const [series] = useState([
    {
      name: 'Page Impression',
      data: [0, 0, 0, 0, 0],
    },
  ]);

  return (
    <div className="flex">
      <div className="mt-4 w-4/6 mr-3 bg-zinc-300 bg-opacity-5 rounded-md backdrop-blur-2xl">
        <div className="p-4 flex items-center mt-2`">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
          >
            <path
              d="M14 20.007H4.00003V18.007H14V20.007ZM21 2L16.265 7.955L16.045 8.225L10.415 15.415C10.2292 15.6008 10.0086 15.7482 9.76582 15.8488C9.52303 15.9494 9.26282 16.0011 9.00003 16.0011C8.73724 16.0011 8.47702 15.9494 8.23423 15.8488C7.99145 15.7482 7.77085 15.6008 7.58503 15.415C7.39921 15.2292 7.25181 15.0086 7.15124 14.7658C7.05068 14.523 6.99892 14.2628 6.99892 14C6.99892 13.7372 7.05068 13.477 7.15124 13.2342C7.25181 12.9914 7.39921 12.7708 7.58503 12.585L21 2ZM1.64503 5.234C0.20056 7.54754 -0.318962 10.3205 0.190027 13H1.00003V12C0.989131 10.031 1.57866 8.10542 2.69003 6.48L1.64503 5.234ZM11 2.69254e-07C9.47071 -0.000338733 7.95826 0.319439 6.55996 0.938762C5.16166 1.55808 3.90848 2.46324 2.88103 3.596L4.02503 4.96C5.38255 3.71173 7.15588 3.0131 9.00003 3C10.8541 3.01293 12.6364 3.71843 13.997 4.978L17.547 2.176C15.6566 0.763256 13.36 -7.74042e-05 11 2.69254e-07ZM17.83 9.2L17.597 9.487L16.869 10.417C16.9541 10.9404 16.9979 11.4697 17 12V13H21.81C22.2846 10.4974 21.8659 7.90762 20.627 5.682L17.83 9.2Z"
              fill="white"
            />
          </svg>
          <p className="ml-2">Performance</p>
        </div>
        <div className="text-black">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={250}
          />
        </div>
      </div>
      <div className="mt-4 w-1/3  bg-zinc-300 bg-opacity-5 rounded rounded-md backdrop-blur-2xl">
        <div className="p-4 flex items-center mt-2`">
         <Svg4 />
          <p className="ml-2">Page Impressions</p>
        </div>
        <div className="text-black">
        <ReactApexChart options={options} series={series} type="bar" height={250} />
        </div>
      </div>
    </div>
  );
}

export default MainCardTwo;
