export const validateEmail = (email) => {
  // Regular expression for a valid email pattern
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

  // Use the test method of the regex pattern to check if the email is valid
  return emailRegex.test(email);
};

// Password validation function
export const validatePassword = (password) => {
  // Check if the password length is at least 8 characters
  return password.length >= 8;
};

export const validateInput = (input) => {
  return input.length >= 3;
};

export const nameShortner = (name, length) => {
  return name?.length > length ? name.slice(0, length) + "..." : name;
};

