import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: 'https://wellverse.fit/v1/',
});

async function getActiveMember(token) {
    try {
      const response = await instance.get('/center/membership', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      // console.log(error);
    }
  }

async function getExpiredMember(token) {
    try {
      const response = await instance.get('/center/expired', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      // console.log(error);
    }
  }

async function createMembershipCall(token, packageId, phone, name, email, startDate ) {
    try {
      const response = await instance.post(
        '/center/membership',
        {
          "name":name,
          "packageId":packageId,
          "phone":phone,
          "email":email,
          "startDate":startDate
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass the authorization token here
          },
        }
      );

      if (response) {
        return response; // Assuming your leads data is in the response's data property
      }
    } catch (error) {
      // console.log(error);
    }
  }


async function getPayOnCenter(token) {
  try {
    const response = await instance.get('/center/payOnCenter', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}


async function getCheckIn(token) {
  try {
    const response = await instance.get('/center/checkIn', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

async function getMembershipDetails(token,id) {
  try {
    const response = await instance.get(`/center/membership/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

// async function createCheckIn(token: string, id) {
//   try {
//     const response: AxiosResponse = await instance.post(`/center/checkIn/${id}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.status === 200) {
//       return response.data // Assuming your leads data is in the response's data property
//     }
//   } catch (error) {
//     // console.log(error);
//   }
// }


async function createCheckIn(token, id) {
  try {
    const response = await instance.get(`/center/checkIn/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

async function deleteMembership(token, id) {
  try {
    const response = await instance.delete(`/center/membership/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

async function deletePayOnCenter(token, id) {
  try {
    const response = await instance.delete(`/center/payOnCenter/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data // Assuming your leads data is in the response's data property
    }
  } catch (error) {
    // console.log(error);
  }
}

export { getActiveMember, createMembershipCall, getExpiredMember, getPayOnCenter,deletePayOnCenter, getCheckIn, getMembershipDetails, createCheckIn, deleteMembership };
