import React, { useCallback, useEffect, useState } from "react";
import {
  LeadsCall,
  createLeadsCall,
  updateLeadsCall,
} from "../../api/Home/Leads";
import { PackageCall } from "../../api/Home/Package";
import Button from "../../components/Button/Button";
import Popup from "../../components/Popup/Popup";
import TextForm from "../../components/TextInput/TextForm";
import Svg5 from "../../../svg/Svg5";
import Svg6 from "../../../svg/Svg6";
import { Link, useLocation } from "react-router-dom";
import {
  validateLeadName,
  validatePhoneNumber,
} from "../../services/validation";
2;
import { nameShortner } from "../../utils/Validator";
import { motion } from "framer-motion";

function Leads() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [leads, setLeads] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("Token") || "");
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]); // State for storing the list of packages
  const [searchQuery, setSearchQuery] = useState("");
  const [isAscendingOrder, setIsAscendingOrder] = useState(false);

  const filterLeads = (query) => {
    setSearchQuery(query);
  };

  const fetchPackages = useCallback(async () => {
    try {
      const response = await PackageCall(token);
      setPackages(response);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  const location = useLocation();
  const { isOpen } = location.state || false;
  useEffect(() => {
    if (isOpen) {
      setPopupVisible(true);
    }
  }, []);
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await LeadsCall(token);
      setLeads(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
    fetchPackages(); // Call fetchPackages when the component mounts
  }, [fetchData, fetchPackages]);

  const handleCreateLeadsClick = () => {
    setPopupVisible(true);
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  function ProspectDetails() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [notes, setNotes] = useState("");
    const [selectedPackage, setSelectedPackage] = useState(""); // State for the selected package
    const [nameValid, setNameValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [planTypeValid, setPlanTypeValid] = useState(true);

    const createLeads = async () => {
      const isLeadNameValid = validateLeadName(name);
      setNameValid(isLeadNameValid);
      const isPhoneValid = validatePhoneNumber(phone);
      setPhoneValid(isPhoneValid);
      const isPlanTypeValid = selectedPackage !== "";
      setPlanTypeValid(isPlanTypeValid);

      if (!isLeadNameValid || !isPhoneValid || !selectedPackage) {
        return;
      }

      try {
        setLoading(true);
        const response = await createLeadsCall(
          token,
          name,
          phone,
          selectedPackage,
          notes,
          "Offline",
          "Pending"
        );
        if (response) {
          fetchData();
          handlePopupClose(); // Close the popup after successful creation
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        fetchData();
        // handlePopupClose();
      }
    };

    const nameStyle = {
      borderColor: nameValid ? "" : "border-red-500",
      color: nameValid ? "" : "text-red-500",
    };
    const phoneStyle = {
      borderColor: phoneValid ? "" : "border-red-500",
      color: phoneValid ? "" : "text-red-500",
    };
    const planTypeStyle = {
      borderColor: phoneValid ? "" : "border-red-500",
      color: phoneValid ? "" : "text-red-500",
    };

    // for Tooltip
    const [hoveredItem, setHoveredItem] = useState("");

    const handleHover = (item) => {
      setHoveredItem(item);
    };

    const handleHoverGo = () => {
      setHoveredItem("");
    };

    return (
      <div className="mt-8">
        <p className="ml-1">Prospect Details</p>
        <TextForm
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Prospect Name"
          placeHolder="Enter lead name"
          style={nameStyle}
        />
        {!nameValid && (
          <p className="text-red-500 ml-1 mt-2 text-xs">
            Lead Name must be alphanumeric and must contain atleast 3 characters
          </p>
        )}
        <TextForm
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          label="Prospect Number"
          placeHolder="Enter Phone Number"
          style={phoneStyle}
        />
        {!phoneValid && (
          <p className="text-red-500 ml-1 mt-2 text-xs">
            Enter a valid Phone Number
          </p>
        )}

        <div className="mt-8">
          <p className="ml-1">Interest</p>
          <div className="flex items-center">
            <div className="mb-4 mt-2 w-full">
              <p
                className={`block text-gray-700 font-bold text-xs mb-2 ${
                  !planTypeValid ? "text-red-500" : ""
                }`}
              >
                Plan Type
              </p>
              <select
                id="planType" // Use a unique ID for the select element
                value={selectedPackage} // Bind the selected value to the state
                onChange={(e) => setSelectedPackage(e.target.value)} // Handle package selection
                // className="text-sm rounded-lg p-4 bg-stone-700 border focus:ring-purple-600 block w-full  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-600 border-gray-600"
                className={`text-sm rounded-lg p-4 bg-stone-700 border focus:ring-purple-600 block w-full dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-600 border-gray-600 ${planTypeStyle.borderColor}`}
              >
                <option value="">Select a Package</option>
                {packages.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {!planTypeValid && (
            <p className="text-red-500 ml-1 text-xs">
              Please select a Plan Type
            </p>
          )}
          {/* <div className="flex items-center">
            <div className="mb-4 mt-2 w-full">
              <p className="block text-gray-700 font-bold text-xs mb-2">
                Plan Name
              </p>
              <select
                id="countries"
                className="text-sm rounded-lg p-4 bg-#0A0A0A bg-transparent border focus:ring-purple-600 block w-full  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-600 border-gray-600"
              >
                <option value="General">General Membership</option>
                <option value="Flexible">Flexible Membership</option>
              </select>
            </div>
          </div> */}
          <TextForm
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            label="Note"
            placeHolder="Enter Details"
          />

          <div className="mb-5" />
          <Button onPress={createLeads} title="Add Leads" />
        </div>
      </div>
    );
  }
  const toggleOrder = () => {
    setIsAscendingOrder(!isAscendingOrder);
  };

  const orderedLeads = isAscendingOrder ? leads : [...leads].reverse();

  // for Tooltip
  const [hoveredItem, setHoveredItem] = useState("");

  const handleHover = (item) => {
    setHoveredItem(item);
  };

  const handleHoverGo = () => {
    setHoveredItem("");
  };
  return (
    <div>
      {isPopupVisible && (
        <Popup title="Add Lead" onClose={handlePopupClose}>
          <ProspectDetails />
        </Popup>
      )}
      <Link to={"/Home"} className="flex ">
        <p className="pb-10 flex items-center justify-center gap-2">
          <svg
            stroke="currentColor"
            fill="#ffffff"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="20px"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke-linecap="square"
              stroke-miterlimit="10"
              stroke-width="48"
              d="M244 400L100 256l144-144M120 256h292"
            ></path>
          </svg>
          Back
        </p>
      </Link>
      <div className="flex items-center">
        <Svg5 />
        <p className="ml-2">Leads</p>
      </div>
      <div className="flex justify-between">
        <div className="mt-10 flex">
          <select
            className="bg-transparent text-sm px-2 py-0.5 bg-#0A0A0A border-2 rounded focus:ring-purple-600 block"
            value={isAscendingOrder ? "oldest" : "newest"}
            onChange={toggleOrder}
          >
            <option value="newest" className="bg-black">
              Newest
            </option>
            <option value="oldest" className="bg-black">
              Oldest
            </option>
          </select>
          <input
            className="bg-transparent px-4 bg-white bg-opacity-10 p-2 ml-4 rounded"
            placeholder="Search by name"
            value={searchQuery}
            onChange={(e) => filterLeads(e.target.value)}
          />
        </div>
        <div>
          <button onClick={handleCreateLeadsClick} className="text-yellow-400">
            <p>+ Create Leads</p>
          </button>
        </div>
      </div>
      <table
        onClick={handlePopupClose}
        className={`w-full mt-10 ${
          isPopupVisible
            ? "opacity-25 transition-all duration-500 ease-in-out"
            : "opacity-100 transition-all duration-500 ease-in-out"
        } `}
      >
        <thead>
          <tr>
            <th className="p-2 text-left">Prospect Name</th>
            <th className="p-2 text-left">Plan Type</th>
            <th className="p-2 text-left">Contact</th>
            <th className="p-2 text-left">Plan Name</th>
            <th className="p-2 text-left">Note</th>
            <th className="p-2 text-left">Mode</th>
            <th className="p-2 pl-4 text-left">Status</th>
            <th className="p-2 text-left">Action</th>
          </tr>
        </thead>
        <tbody className="max-h-40 text-sm overflow-y-auto">
          {orderedLeads
            .filter((lead) =>
              lead.customerName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            )
            .map((lead) => (
              <tr key={lead.id}>
                <td className="p-2 w-48 text-left border-b border-neutral-700">
                  {lead.customerName}
                </td>
                <td className="p-2 w-48 text-left border-b border-neutral-700">
                  {lead.interest?.package?.name || "NULL"}
                </td>
                <td className="p-2 w-44 text-left border-b border-neutral-700">
                  {lead.mobileNumber}
                </td>
                <td className="p-2 w-44 text-left border-b border-neutral-700">
                  {lead.interest?.package?.package_type || "NULL"}
                </td>
                <td
                  key={lead.id}
                  className={`text-left cursor-pointer relative w-40 px-2  border-b border-neutral-700 }`}
                  onMouseEnter={() => handleHover(lead._id)}
                  onMouseLeave={handleHoverGo}
                >
                  {/* // console.log(hoveredItem, lead._id) */}
                  {nameShortner(lead.note, 10)}
                  {hoveredItem === lead._id && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className={`absolute bg-white dropdown-scroll  max-w-xl max-h-14 overflow-y-scroll z-10 text-black p-1 rounded text-xs `}
                    >
                      <p className="overflow-auto w-[120px] h-[90px] ">
                        {lead.note}
                      </p>
                    </motion.div>
                  )}
                </td>
                <td className="p-2 w-32 text-left border-b border-neutral-700">
                  {lead.mode}
                </td>
                <td className="text-left border-b w-36 px-2 border-neutral-700">
                  <p
                    className={`text-center py-1 w-28 rounded-full ${
                      lead.status === "Converted"
                        ? "bg-green-500 bg-opacity-30"
                        : lead.status === "Pending"
                        ? "bg-yellow-500 bg-opacity-30"
                        : lead.status === "In Discussion"
                        ? "bg-blue-400 bg-opacity-30"
                        : lead.status === "Attempted"
                        ? "bg-purple-500 bg-opacity-30"
                        : lead.status === "Disqualified"
                        ? "bg-red-500 bg-opacity-30"
                        : "" // Add an empty string as a fallback
                    }`}
                  >
                    {lead.status}
                  </p>
                </td>

                <td className="p-2 w-40 text-left border-b border-neutral-700">
                  <select
                    value={lead.status} // Set the selected value based on lead.status
                    onChange={(e) => {
                      const newStatus = e.target.value;
                      updateLeadsCall(token, newStatus, lead._id)
                        .then(() => fetchData())
                        .catch((error) => console.error(error));
                    }}
                    className="text-sm rounded-lg p-2 bg-stone-800 border focus:ring-purple-600 block dark:bg--900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-600 border-gray-600"
                  >
                    <option value="Converted">Converted</option>
                    <option value="Pending">Pending</option>
                    <option value="In Discussion">In Discussion</option>
                    <option value="Attempted">Attempted</option>
                    <option value="Disqualified">Disqualified</option>
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leads;
