import React, { useCallback, useEffect, useRef, useState } from "react";
import { createMembershipCall } from "../../api/Home/Membership";
import {
  PackageCall,
  createPackageCall,
  deletePackageCall,
  editPackageCall,
} from "../../api/Home/Package";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import Popup from "../../components/Popup/Popup";
import SelectTags from "../../components/SelectTags/SelectTags";
import TextForm from "../../components/TextInput/TextForm";
import { motion } from "framer-motion";
import Svg1 from "../../../svg/Svg1";
import Svg2 from "../../../svg/Svg2";
import {
  validateName,
  validatePhoneNumber,
  validateEmail,
  validatePackageDuration,
  validatePackagePrice,
} from "../../services/validation/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendarDate } from "react-icons/ci";
import { FaCalendar } from "react-icons/fa";
import MessageCard from "../../components/MessageCard/MessageCard";
import { useNavigate } from "react-router-dom";

const listBenifits = ["Access To Zumba", "Access To Yoga", "Free Diet Plan"];

// interface PackageProps {}

function Package(props) {
  const [activeMembership, setActiveMembership] = useState("general");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("Token"));
  const [packages, setPackages] = useState([]);
  const [benifits, setBenifits] = useState([]);
  const [package_name, setPackage_name] = useState("");
  const [packageDuration, setPackageDuration] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [editingPackage, setEditingPackage] = useState(null);
  const [userDetailsPopupVisible, setUserDetailsPopupVisible] = useState(false); // State to manage the user details popup visibility
  const [selectedPackageForAssign, setSelectedPackageForAssign] = useState();
  const [isEditingFlexiblePackage, setIsEditingFlexiblePackage] =
    useState(false);

  const [couponCode, setCouponCode] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(0);

  const [packageNameValid, setPackageNameValid] = useState(true);
  const [durationValid, setDurationValid] = useState(true);
  const [priceValid, setPriceValid] = useState(true);
  const [createPackageSuccess, setCreatePackageSuccess] = useState(false);
  const [saveChangesSuccess, setSaveChangesSuccess] = useState(false);
  // For Delete Package Confirmation
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [listOnMarketplace, setListOnMarketplace] = useState(false);

  // Loader
  const [isLoading, setIsLoading] = useState(false);
  // // console.log("ISL",listOnMarketplace)
  useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openUserDetailsPopup = (packageToAssign) => {
    setSelectedPackageForAssign(packageToAssign);
    setUserDetailsPopupVisible(true);
  };

  // Function to close the user details popup
  const closeUserDetailsPopup = () => {
    setUserDetailsPopupVisible(false);
  };
  useEffect(() => {
    const handleClickOutside = (e) => {
      const isDropdown =
        e.target instanceof Element && e.target.closest(`#dropdown`);
      const isButton =
        e.target instanceof Element && e.target.closest("#button");
    

      if (
        !isDropdown &&
        !isButton &&
        
        (isPopupVisible )
      ) {
        setPopupVisible(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [
    isPopupVisible,
    
    setPopupVisible,
  
  ]);

  const [assignPopup, setAssignPopup] = useState(false);

  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/Membership");
  };

  const handleContinueCreatePackage = () => {
    setSaveChangesSuccess(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await PackageCall(token);
      setPackages(response);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePopupClose = () => {
    setEditingPackage(null);
    setPackage_name("");
    setPackageDuration("");
    setPackagePrice(undefined);
    setBenifits([]);
    setListOnMarketplace(false);
    setPopupVisible(false);
  };

  const handleEditPackage = (packageToEdit) => {
    setEditingPackage(packageToEdit);
    setPackage_name(packageToEdit.name);
    setPackageDuration(packageToEdit.validity);
    setPackagePrice(packageToEdit.price);
    setIsEditingFlexiblePackage(packageToEdit.package_type === "flexible");
    setBenifits(packageToEdit.benefits);
    setPopupVisible(true);
  };

  const handleDeletePackage = async () => {
    if (editingPackage) {
      setLoading(true);
      try {
        const response = await deletePackageCall(token, editingPackage._id);
        if (response) {
          setEditingPackage(null);
          setPopupVisible(false);
          fetchData();
        }
      } catch (error) {
        setError("An error occurred while deleting the package.");
      } finally {
        setLoading(false);
      }
    }
    setShowDeleteConfirmation(false);
  };

  const handleOpenModal = async () => {
    setShowDeleteConfirmation(true);
  };

  const handleCloseModal = () => {
    setShowDeleteConfirmation(false);
  };

  const handleCreateMembershipButton = async () => {
    setLoading(true);

    const isPackageNameValid = validateName(package_name);
    setPackageNameValid(isPackageNameValid);

    const isPackagePriceValid = validatePackagePrice(packagePrice);
    setPriceValid(isPackagePriceValid);
setIsLoading(true)
    try {
      if (isPackageNameValid && isPackagePriceValid) {
        if (editingPackage) {
          const response = await editPackageCall(
            token,
            editingPackage._id,
            package_name,
            packagePrice,
            packageDuration,
            listOnMarketplace
          );
          console.log(listOnMarketplace)
setIsLoading(false)
          if (response) {
            setSaveChangesSuccess(true);
            setEditingPackage(null);
          }
        } else {
          const response = await createPackageCall(
            token,
            package_name,
            packagePrice,
            activeMembership === 'flexible' ? undefined : packageDuration,
            benifits
          );

          if (response) {
            setCreatePackageSuccess(true);
            console.log("hi");
          }
        }

        setPackage_name("");
        setPackageDuration("");
        setPackagePrice(undefined);
        setBenifits([]);
        // setPopupVisible(false);
        fetchData();
      } else {
        setError("Invalid package details. Please check the fields.");
      }
    } catch (error) {
      setError("An error occurred while saving the package.");
      console.error("Error occurred while saving the package:", error);
    } finally {
      setLoading(false);
    }
  };

  // onClick={() => openUserDetailsPopup(item)}
  // function MembershipContent({ packages }: { packages: any[] }) {
  //   return (
  //     <div className="grid grid-cols-4 gap-4">
  //       {packages.map((item: any) => (
  //         <div
  //           className="border border-stone-500 rounded p-4 bg-neutral-700"
  //           key={item._id}
  //         >
  //           <div className="flex justify-between items-center">
  //             <p className="">{item.name}</p>x`
  //             <button
  //               className="bg-blue-500 p-1 rounded text-xs"
  //               onClick={() => handleEditPackage(item)}
  //             >
  //               Edit Package
  //             </button>
  //           </div>
  //           <div className="flex gap-3 text-sm mt-3">
  //             <p>Session: {item.session}</p>
  //             <p>Validity: {item.validity}</p>
  //           </div>
  //           <p className="text-sm mb-6 mt-1">{item.details}</p>
  //           <button>
  //             <p className="px-2 bg-white rounded justify-between items-center text-black">
  //               ₹{item.price}
  //             </p>
  //           </button>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  function MembershipContent({ packages }) {
    return (
      <motion.div className="grid grid-cols-3 gap-4">
        {packages.map((item) => (
          <div className="p-4 flex" key={item._id}>
            <div className="p-3 rounded-s-md w-2/3 bg-neutral-800">
              <p className="text-lg font-bold mb-2">{item.name}</p>
              <div className="flex items-center gap-1">
                <Svg1 />
                <p className="text-sm text-neutral-500">
                  {" "}
                  {item.session} Days Access
                </p>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <Svg2 />
                <p className="text-sm text-neutral-500">
                  {" "}
                  {item.validity} Days validity
                </p>
              </div>
              <p className="mt-3">Benefits</p>
              <div className="flex gap-2">
                <p className="text-sm text-neutral-500">Zumba Access</p>
                <p className="text-sm text-neutral-500">Yoga Access</p>
              </div>
            </div>
            <div className=" w-1/3 rounded-e-md flex-col justify-between bg-neutral-900 items-center py-3">
              <div className="justify-center items-center text-center">
                <p className="text-lg font-bold">₹ {item.price}/-</p>
                <p className="text-xs text-neutral-500">
                  Inclusive of all taxes
                </p>
              </div>
              <div className="h-full justify-center items-center p-3">
                <button
                  id="button1"
                  onClick={() => openUserDetailsPopup(item)}
                  className="bg-purple-600 w-full py-2 rounded"
                >
                  <p className="text-xs">Assign Plan</p>
                </button>
                <button
                  id="button"
                  onClick={() => handleEditPackage(item)}
                  className="border border-white mt-2 rounded w-full py-2"
                >
                  <p className="text-xs">Edit Plan</p>
                </button>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    );
  }

  function UserDetailsPopup({ packageInfo }) {
    const [step, setStep] = useState(1);
    const [memberName, setMemberName] = useState("");
    const [memberNumber, setMemberNumber] = useState("");
    const [memberEmail, setMemberEmail] = useState("");
    const [step1Valid, setStep1Valid] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [assignSuccess, setAssignSuccess] = useState(false);

    const [nameValid, setNameValid] = useState(true);
    const [numberValid, setNumberValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    const isNameValid = validateName(memberName);
    const isNumberValid = validatePhoneNumber(memberNumber);
    const isEmailValid = validateEmail(memberEmail);
    // For Yes No button in Next Confirmation
    const handleNext = (event) => {
      event.preventDefault();
      // // console.log("From On Next Click",isNameValid,isNumberValid,isEmailValid);
      // // console.log(memberName)
      if (isNameValid && isNumberValid && isEmailValid) {
        setIsSubmitted(true);
      } else {
        // Handle validation failure here, e.g., show an error message
        setIsSubmitted(false);
      }
    };
    const nextStep = (event) => {
      event.stopPropagation();
      setIsLoading(true); // Start loading
      setNameValid(isNameValid);
      setNumberValid(isNumberValid);
      setEmailValid(isEmailValid);
      if (isNameValid && isNumberValid && isEmailValid) {
        setStep(step + 1);
        setStep1Valid(true);
        // // console.log("from If else",isNameValid,isNumberValid,isEmailValid);
      }
      setIsLoading(false); // End loading
    };

    const handleCreateMembership = async () => {
      // const response = await createMembershipCall(
      //   token,
      //   packageInfo._id,
      //   memberNumber,
      //   memberName,
      //   memberEmail,
      //   selectedDate
      // );
      // if (response) {
      //   // closeUserDetailsPopup();
      // }

      try {
        if (isNameValid && isNumberValid && isEmailValid) {
          // Create membership logic...
          // Assuming the membership is successfully assigned:
          const response = await createMembershipCall(
            token,
            packageInfo._id,
            memberNumber,
            memberName,
            memberEmail,
            selectedDate
          );
          if (response) {
            setAssignSuccess(true); // Set the state to show the success message
            // closeUserDetailsPopup(); // Close the user details popup
            // // console.log(memberName+ '2')
          }
        } else {
          setError("Invalid details. Please check the fields.");
        }
      } catch (error) {
        setError("An error occurred while assigning the membership.");
      } finally {
        setLoading(false);
      }
    };

    // const handleDateChange = (e) => {
    //   setSelectedDate(e.target.value);
    // };

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    const prevStep = () => {
      setStep(step - 1);
    };

    const handleConfirm = () => {
      // Handle the form submission or any other necessary action
      // For example, you can collect form data and close the popup
      // Don't forget to add the necessary logic here.

      // Close the popup
      closeUserDetailsPopup();
    };

    const nameStyle = {
      borderColor: nameValid ? "" : "border-red-500",
      color: nameValid ? "" : "text-red-500",
    };
    const emailStyle = {
      borderColor: emailValid ? "" : "border-red-500",
      color: emailValid ? "" : "text-red-500",
    };

    const numberStyle = {
      borderColor: numberValid ? "" : "border-red-500",
      color: numberValid ? "" : "text-red-500",
    };

    const userDetailsPopupRef = useRef(); // Step 1: Create a ref

    useEffect(() => {
      function handleClickOutside(event) {
        if (userDetailsPopupRef.current && !userDetailsPopupRef.current.contains(event.target)) {
          setUserDetailsPopupVisible(false); // Step 4: If the click is outside, close the popup
        }
      }
  
      // Step 2: Add the mousedown event listener
      document.addEventListener("mousedown", handleClickOutside);
  
      // Step 5: Clean up the event listener on component unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return (
      <div ref={userDetailsPopupRef}>
      <Popup title="User Details" onClose={closeUserDetailsPopup}>
        <div className="mt-20">
          {step === 1 && (
            <div className={`${isLoading ? "opacity-10" : "opacity-100"}`}>
              <TextForm
                label="Enter Member Name"
                placeHolder="Client Name"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                style={nameStyle}
              />
              {!nameValid && (
                <p className="text-red-500 ml-1 text-xs">
                  Name should contain atleast 3 characters
                </p>
              )}

              <TextForm
                label="Enter Member Number"
                placeHolder="000 0000 000"
                value={memberNumber}
                onChange={(e) => setMemberNumber(e.target.value)}
                style={numberStyle}
              />
              {!numberValid && (
                <p className="text-red-500 ml-1 text-xs">
                  Number should contain atleast 10 digits
                </p>
              )}

              <TextForm
                label="Enter Member Email"
                placeHolder="sample@fitverse.com"
                value={memberEmail}
                onChange={(e) => setMemberEmail(e.target.value)}
                style={emailStyle}
              />
              {!emailValid && (
                <p className="text-red-500 ml-1 text-xs">
                  Please Enter a Valid Email
                </p>
              )}
              {/* <Button
                title="Next"
                onPress={nextStep}
                // disabled={!step1Valid}
              /> */}
              <button
                onClick={nextStep}
                className={`bg-purple-600 w-full ${
                  isSubmitted ? "hidden" : "block"
                } py-2 rounded mt-5`}
              >
                Next
              </button>
            </div>
          )}
              {isLoading && (
                <>
                <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
        <svg aria-hidden="true" class="w-14 h-16  text-gray-200 animate-spin dark:text-white fill-[#9333EA]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
        <span class="sr-only">Loading...</span>
    </div>
                </>
              )}
          {step === 2 && (
            <div className="text-xs">
              {/* Display step 2 content here */}
              <p className="text-sm">Client Name : {memberName}</p>
              <div className="flex mt-5">
                <div className="w-1/4">
                  <p className="bg-neutral-700 p-1 mb-2">Plan Name</p>
                  <p className="p-1">{packageInfo.name}</p>
                </div>
                <div className="w-1/4">
                  <p className="bg-neutral-700 p-1 mb-2">Plan Session</p>
                  <p className="p-1">{packageInfo.session}</p>
                </div>
                <div className="w-1/4">
                  <p className="bg-neutral-700 p-1 mb-2">Plan Validity</p>
                  <p className="p-1">{packageInfo.validity}</p>
                </div>
                <div className="w-1/4">
                  <p className="bg-neutral-700 mb-2 p-1">Plan Price</p>
                  <p className="p-1">₹ {packageInfo.price}/-</p>
                </div>
              </div>
              <p className="mt-5 mb-2">Select A Start Date</p>
              {/* <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                placeholder="Select A Start Date"
                className="border border-gray-300 rounded p-4 w-full mb-5 bg-transparent"
              /> */}
              <div className="relative w-full">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300 rounded p-4 w-full mb-5 bg-transparent cursor-pointer"
                  wrapperClassName="w-full"
                  popperPlacement="top-start"
                  style={{ width: "100%" }} // Adjust padding for icon space
                />
                <span className=" right-5 top-5" onClick={(event)=>event.stopPropagation()}>
                  <FaCalendar className="absolute"  onClick={(event)=>event.stopPropagation()} />
                </span>
              </div>

              <div className="flex items-center mb-6 gap-2">
                <input
                  type="text"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter Coupon Code"
                  className="border border-gray-300 rounded w-3/4 p-2"
                />
                <button
                  title="Apply"
                  //  onPress={applyCoupon}
                  className=" bg-purple-600 rounded p-2 text-white w-1/4"
                >
                  Apply
                </button>
              </div>

              <div className="flex justify-center items-center">
                <div className="w-28 h-px mx-4 border border-zinc-600"></div>
                <div className="Chiesel20 text-white text-base font-medium">
                  Bill Summary
                </div>
                <div className="w-28 h-px mx-4 border border-zinc-600"></div>
              </div>
              <div className="mt-5 bg-neutral-700 bg-opacity-60 text-sm rounded">
                <div className="flex justify-between p-3 border-b-2 border-zinc-600">
                  <p>Subtotal</p>
                  <p>₹{packageInfo.price}/-</p>
                </div>
                <div className="flex justify-between p-3 border-b-2 border-zinc-600">
                  <p>Grand Total</p>
                  <p>₹{packageInfo.price}/-</p>
                </div>
                <div className="flex justify-between p-3">
                  <p>Net Payable</p>
                  <p>₹{packageInfo.price}/-</p>
                </div>
              </div>
              <div className="text-base">
                <Button
                  onPress={handleCreateMembership}
                  title="Create Membership"
                />
                {assignSuccess && (
                  <MessageCard
                    title="Success"
                    onContinue={handleContinue}
                    onClose={() => setAssignSuccess(false)}
                  >
                    <p className="text-lg">Membership Successfully Assigned!</p>
                    <p className="mt-6 text-sm text-center">
                      Click on continue to view all memberhips
                    </p>
                  </MessageCard>
                )}
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="text-xs">{/* Display step 2 content here */}</div>
          )}
        </div>
      </Popup>
      </div>
    );
  }

  const packageNameStyles = {
    borderColor: packageNameValid ? "" : "border-red-500",
    color: packageNameValid ? "" : "text-red-500",
  };

  const durationStyle = {
    borderColor: durationValid ? "" : "border-red-500",
    color: durationValid ? "" : "text-red-500",
  };

  const priceStyle = {
    borderColor: priceValid ? "" : "border-red-500",
    color: priceValid ? "" : "text-red-500",
  };

  const handlePackagePopup = (e) => {
    e.stopPropagation();
    setPopupVisible(true);
  };

  return (
    <div className={``}>
      {/* // console.log(isPopupVisible, "div before") */}
      {isPopupVisible && (
        <div className={``}>

        <Popup
          title={editingPackage ? "Edit Package" : "Create Package"}
          onClose={handlePopupClose}
       
        >
          {/* // console.log(isPopupVisible, "fromPopup") */}
          <div className={`mt-20 ${isLoading ? "" : "opacity-100"} `}>
            <TextForm
              value={package_name}
              onChange={(e) => setPackage_name(e.target.value)}
              label={"Enter Plan Name"}
              placeHolder="Package Name Will Display On Marketplace"
              style={packageNameStyles}
            />
            {!packageNameValid && (
              <p className="text-red-500 ml-1 text-xs">
                Plan Name should contain atleast 3 characters
              </p>
            )}
           { activeMembership !== 'flexible' && <TextForm
              value={packageDuration}
              onChange={(e) => setPackageDuration(e.target.value)}
              label={"Enter Plan Duration"}
              placeHolder="In Days"
              style={durationStyle}
            />}
            {!durationValid && activeMembership !== 'flexible' && (
              <p className="text-red-500 ml-1 text-xs">
                Please Add Valid Number of Days
              </p>
            )}
            <TextForm
              value={packagePrice}
              onChange={(e) => setPackagePrice(e.target.value)}
              label={"Enter Plan Price"}
              placeHolder="In Rupees"
              style={priceStyle}
            />
            {!priceValid && (
              <p className="text-red-500 ml-1 text-xs">
                Please Add Valid Price
              </p>
            )}
            <p className="block text-xs text-gray-700 font-bold mt-10">
              Select Benefits
            </p>
            <SelectTags
              onChange={setBenifits}
              list={listBenifits}
              selectedTags={benifits}
            />
            <Button
              onPress={handleCreateMembershipButton}
              title={editingPackage ? "Save Changes" : "Create Package"}
            />
            {isLoading && (
                <>
                <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
        <svg aria-hidden="true" class="w-14 h-16  text-gray-200 animate-spin dark:text-white fill-[#9333EA]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
        <span class="sr-only">Loading...</span>
    </div>
                </>
              )}
            {createPackageSuccess && (
              <MessageCard
                title="Success"
                onContinue={handleContinueCreatePackage}
                onClose={() => setCreatePackageSuccess(false)}
              >
                <p className="text-lg">Your New Package is all set to go!</p>
              </MessageCard>
            )}
            {saveChangesSuccess && (
              <MessageCard
                title="Success"
                onContinue={handleContinueCreatePackage}
                onClose={() => setSaveChangesSuccess(false)}
              >
                <p className="text-lg">Your Package is updated successfully!</p>
              </MessageCard>
            )}
            {editingPackage && (
              <button
                onClick={handleOpenModal}
                className={`w-full p-2 bg-red-500 ${
                              showDeleteConfirmation ? "hidden" : "block"
                            } rounded mt-5`}
                          >
                            <p>Delete Package</p>
                          </button>
                        )}
                        {showDeleteConfirmation && (
                          <motion.div
                            initial={{ x: "100%", opacity: 0 }} // Start from the right and invisible
                            animate={{ x: 0, opacity: 1 }} // Move to the left and become visible
                            transition={{ duration: 0.5 }} // Animation duration
                            className=" flex mt-5 flex-col justify-center item-center gap-5"
                          >
                            <div className="text-center">
                              <p>Do you really want to delete this ?</p>
                            </div>

                            <div className="flex flex-row justify-center item-center gap-5">
                              <button
                                className="bg-red-500 px-10 py-2 rounded-lg"
                                onClick={handleDeletePackage}
                              >
                                Yes
                              </button>
                              <button
                                className="bg-green-500 px-10 py-2 rounded-lg"
                                onClick={handleCloseModal}
                              >
                                No
                              </button>
                            </div>
                          </motion.div>
                        )}
                      </div>
                    </Popup>
                    </div>
                  )}
                  {userDetailsPopupVisible && (
                    <UserDetailsPopup packageInfo={selectedPackageForAssign} />
                  )}
                  <div className="w-full flex justify-center gap-5 ">
                    <button
                      className={`${
                        activeMembership === "general" ? "border-b-2 border-purple-500" : ""
                      } px-4 py-2`}
                      onClick={() => setActiveMembership("general")}
                    >
                      General Membership
                    </button>
                    <button
                      onClick={() => setActiveMembership("flexible")}
                      type="button"
                      className={`${
                        activeMembership === "flexible"
                          ? "border-b-2 border-purple-500"
                          : ""
                      } px-4 py-2`}
                    >
                      Flexible Membership
                    </button>
                  </div>
                  {activeMembership === "general" && (
                    <div className="flex justify-end ">
                      <button
                        onClick={(e) => handlePackagePopup(e)}
                        className="bg-purple-500 px-4 py-2 rounded"
                      >
                        <p>+ {editingPackage ? "Edit Package" : "Create Package"}</p>
                      </button>
                    </div>
                  )}

                  <div
                    className={`mt-10 ${
                      isPopupVisible
                        ? "opacity-25 transition-all duration-500 ease-in-out"
                        : "opacity-100 transition-all duration-500 ease-in-out"
                    }`}
                  >
                    {loading && <Loading />}
                    {error && <p>{error}</p>}
                    {activeMembership === "general" && !loading && (
                      <div>
                        {packages.some((item) => item.package_type === "general") ? (
                          <MembershipContent
                            packages={packages.filter(
                              (item) => item.package_type === "general"
                            )}
                          />
                        ) : (
                          <p>
                            No ongoing general packages found. Create a new package to view.
                          </p>
                        )}
                      </div>
                    )}
        {activeMembership === "flexible" && !loading && (
          <div>
            {packages.some((item) => item.package_type === "flexible") ? (
              <MembershipContent
                packages={packages.filter(
                  (item) => item.package_type === "flexible"
                )}
              />
            ) : (
              <p>No ongoing flexible packages found.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Package;
