import { motion } from "framer-motion";
import React from "react";
import success from "../../../images/success1.png";
import Lottie from "lottie-react";
import successAnimation from "../Animation/Success.json"

function MessageCard({ title, onClose, children,onContinue }) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }} // Initial scale and opacity for animation
      animate={{ opacity: 1, scale: 1 }} // Animation for card appearing
      exit={{ opacity: 0, scale: 0.5 }} // Animation for card disappearing
      transition={{ duration: 0.3 }} // Animation duration
      className="fixed top-0 left-0 transform -translate-x-1/2 h-full w-full bg-neutral-800  rounded-md shadow-md z-50"
    >
      <div className=" p-4 rounded-md">
      <button onClick={onClose}>
            <svg
              viewBox="0 0 512 512"
              fill="currentColor"
              height="24"
              width="24"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={48}
                d="M328 112L184 256l144 144"
              />
            </svg>
          </button>
        <div className="relative flex flex-col mt-24 justify-between items-center">
        <Lottie className="absolute h-5/6 top-0" animationData={successAnimation} loop={false} />
          <div className="relative flex flex-col">
          
            <img src={success} alt="success" className="h-36 w-36 mt-10 mx-auto" />
          </div>
          <div className="mt-5">{children}</div>
          <button
            type="button"
            onClick={onContinue}
            className="bg-purple-600 rounded w-80 py-4 font-bold text-sm mt-40"
          >
            Continue
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default MessageCard;
