import React, { useCallback, useEffect, useState } from 'react';
import { getCenterDetails } from '../../api/Root';
import Svg18 from '../../../svg/Svg18';
import { motion } from 'framer-motion';
import avatar from "../../../images/avatar3.jpeg"

function CenterDetails() {
  const [data, setdata] = useState({});
  const [openDropDown, setOpenDropDown] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem('Token')
  );
  const [rotation, setRotation] = useState(0); // New state variable for rotation angle

  const fetchData = useCallback(async () => {
    try {
      const response = await getCenterDetails(token);
      setdata(response);
    } catch (error) {
    } finally {
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onLogout = () => {
    localStorage.removeItem('Token');
    localStorage.removeItem('RefreshToken');
    window.location.reload();
  }

  const logoutHandler = () => {
    setOpenDropDown(false);
    onLogout()
  }

  const toggleDropDown = () => {
    setOpenDropDown(!openDropDown);
  }

  const handleSvg = () => {
    setRotation(rotation === 0 ? 180 : 0); // Toggle rotation angle between 0 and 90 degrees
  }

  // console.log(data)
  return (
    <div className="flex gap-3 items-center relative ">
      <div>
        <p>{data.details?.name || "ERROR"}</p>
        <p className="text-xs text-neutral-500">{data.details?.email || "ERROR"}</p>
      </div>
      <img
        className="h-10 w-10 rounded-full"
        // src="https://static.vecteezy.com/system/resources/thumbnails/017/504/043/small/bodybuilding-emblem-and-gym-logo-design-template-vector.jpg"
        src={avatar}
      />
      <div onClick={toggleDropDown} className='cursor-pointer'>
   <Svg18 />
      </div>
      {openDropDown && (
        <>
          {/* dropdown */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            exit={{ y: 20, opacity: 0 }}
            
            className=' flex border-2 bg-[#151414] border-[#a855f7] rounded-lg items-center justify-center px-5 top-[50px] absolute right-[-10px] z-50'
          >
            <button
              onClick={() => logoutHandler()}
              className="   flex-row items-center w-full gap-2 px-4 py-1   text-lg font-bold text-white bold"
            >
              Logout 
            </button>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0,0,256,256"><g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode: 'normal'}}><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.61547,0 -12,5.38453 -12,12c0,6.61547 5.38453,12 12,12c2.55492,0 4.9167,-0.81857 6.85352,-2.18164c0.45197,-0.31767 0.56084,-0.94159 0.24316,-1.39355c-0.31767,-0.45197 -0.94159,-0.56084 -1.39355,-0.24316c-1.62118,1.14093 -3.57405,1.81836 -5.70312,1.81836c-5.53453,0 -10,-4.46547 -10,-10c0,-5.53453 4.46547,-10 10,-10c2.12908,0 4.08194,0.67742 5.70313,1.81836c0.45197,0.31767 1.07588,0.2088 1.39355,-0.24316c0.31767,-0.45197 0.2088,-1.07588 -0.24316,-1.39355c-1.93682,-1.36307 -4.29859,-2.18164 -6.85352,-2.18164zM22.99023,9.99023c-0.40692,0.00011 -0.77321,0.24676 -0.92633,0.62377c-0.15312,0.37701 -0.06255,0.80921 0.22907,1.09303l2.29297,2.29297h-10.58594c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h10.58594l-2.29297,2.29297c-0.26124,0.25082 -0.36648,0.62327 -0.27512,0.97371c0.09136,0.35044 0.36503,0.62411 0.71547,0.71547c0.35044,0.09136 0.72289,-0.01388 0.97371,-0.27512l3.91211,-3.91211c0.24834,-0.18944 0.39391,-0.48403 0.39353,-0.79638c-0.00038,-0.31235 -0.14668,-0.60658 -0.39548,-0.79541l-3.91016,-3.91016c-0.18827,-0.19353 -0.4468,-0.30272 -0.7168,-0.30273z"></path></g></g></svg>
          </motion.div>
        </>
      )}
    </div>
  );
}

export default CenterDetails;
