import React, { useCallback, useEffect, useState } from 'react';
import {
  createMembershipCall,
  deletePayOnCenter,
  getPayOnCenter,
} from '../../api/Home/Membership';
import Loading from '../../components/Loading/Loading';
import Svg3 from '../../../svg/Svg3';

function formatDate(isoDate) {
  const date = new Date(isoDate);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

function PayOnCenter() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem('Token')
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await getPayOnCenter(token);
      setData(response.members);
      setError(null);
    } catch (error) {
      setError(error.message); // Handle the error and display a message
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <p className="">Pay On Center Request</p>
      <div className="mt-10 flex">
        <input
          className="bg-transparent px-4 bg-white bg-opacity-10 p-2 rounded"
          placeholder="Search by name"
        />
      </div>
      {loading ? ( // Conditionally render loading component
        <Loading />
      ) : (
        <table className="w-full mt-10">
          <thead>
            <tr>
              <th className="p-2 text-left">Client Name</th>
              <th className="p-2 text-left">Plan Type</th>
              <th className="p-2 text-left">Plan Name</th>
              <th className="p-2 text-left">Contact</th>
              <th className="p-2 text-left">Start Date</th>
              <th className="p-2 text-left">Price</th>
              <th className="p-2 text-left">Action</th>
            </tr>
          </thead>
          <tbody className="max-h-40 text-sm overflow-y-auto">
            {data.map((lead) => (
              <tr key={lead._id}>
                <td className="p-2 text-left">{lead.UserName}</td>
                <td className="p-2 text-left">{lead.PackageType}</td>
                <td className="p-2 text-left">{lead.PackageName}</td>
                <td className="p-2 text-left">{lead.UserPhone}</td>
                <td className="p-2 text-left">{formatDate(lead.StartDate)}</td>
                <td className="p-2 text-left">₹ {lead.PackagePrice}/-</td>
                <td className="p-2 text-left">
                  <div className="">
                    <button
                      onClick={async () => {
                        setLoading(true); // Set loading to true before making the API call
                        try {
                          const response = await createMembershipCall(
                            token,
                            lead.PackageId,
                            lead.UserPhone,
                            lead.UserName,
                            lead.UserPhone,
                            lead.StartDate
                          );
                          if (response) {
                            const response = await deletePayOnCenter(
                              token,
                              lead._id
                            );
                            if (response) {
                              fetchData(); // Fetch data again after successful API call
                            }
                          }
                        } catch (error) {
                          setError(error.message); // Handle the error and display a message
                        } finally {
                          setLoading(false); // Set loading to false after API call is complete
                        }
                      }}
                      className="bg-green-500 mr-3 p-1 rounded-full"
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        height="2em"
                        width="2em"
                      >
                        <path d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z" />
                      </svg>
                    </button>
                    <button
                      onClick={async () => {
                        setLoading(true); // Set loading to true before making the API call
                        try {
                          const response = await deletePayOnCenter(token, lead._id);
                          if (response) {
                            fetchData(); // Fetch data again after successful API call
                          }
                        } catch (error) {
                          setError(error.message); // Handle the error and display a message
                        } finally {
                          setLoading(false); // Set loading to false after API call is complete
                        }
                      }}
                      className="bg-red-400 p-1 rounded-full"
                    >
                     <Svg3 />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default PayOnCenter;
