import React, { useCallback, useEffect, useState } from 'react';
import { gravityLogo } from '../../constant/Images';
import CenterDetails from './CenterDetails';
import NotificationButton from './NotificationButton';
import { getCenterDetails } from '../../api/Root';

function Navbar() {
  return (
    <header className="sticky top-0 z-10 flex w-full py-5 bg-[#121212] drop-shadow-1 white">
      <div className="flex flex-grow items-center justify-between py-4 shadow-2 px-10">
        <div className="flex w-full items-center justify-between 2xsm:gap-7">
          <div>
            <img src={gravityLogo} alt="logo" className="h-12" />
          </div>
          <ul className="flex items-center gap-2 2xsm:gap-4">
          {/* <NotificationButton/> */}
            <CenterDetails/>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
