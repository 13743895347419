import React from 'react'

const Svg = () => {
  return (
    <div>
            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                >
                  <path
                    d="M1.19092 6.66108H13.8162"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6466 9.42671H10.6532"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.50304 9.42671H7.5096"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.35363 9.42671H4.36019"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6466 12.1806H10.6532"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.50304 12.1806H7.5096"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.35363 12.1806H4.36019"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.3642 1.41602V3.74698"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.64207 1.41602V3.74698"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.5021 2.53418H4.50443C2.42428 2.53418 1.125 3.69297 1.125 5.82299V12.2332C1.125 14.3967 2.42428 15.5822 4.50443 15.5822H10.4955C12.5823 15.5822 13.875 14.4168 13.875 12.2867V5.82299C13.8815 3.69297 12.5888 2.53418 10.5021 2.53418Z"
                    stroke="white"
                    stroke-width="1.0625"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
    </div>
  )
}

export default Svg