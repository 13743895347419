import React from 'react'

const Svg9 = () => {
  return (
    <div>
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
          >
            <path
              d="M10.245 0L6.49969 3.94266L6.6075 6.70031L8.97937 8.39719L10.4747 16.9284L10.9809 15.6675L11.5341 4.63313L12.4106 4.68L11.9841 13.1644L13.3106 9.84562L13.695 4.78312L13.7372 3.94266L10.245 0ZM16.7606 2.37047L14.5763 4.75031L14.3934 7.14563L14.8903 5.9175L16.3153 5.27531C16.4653 4.30969 16.6106 3.33938 16.7606 2.37047ZM19.5872 4.76906L15.57 6.56906L10.7606 18.5644L11.445 22.4691H11.8106L18.7294 8.73L19.5122 9.12375L12.7903 22.4691H13.9341L20.8059 8.79094L19.5872 4.76906ZM3.64031 5.655L1.10391 10.3003L4.34344 22.4691H8.73094L6.33562 9.58781L7.19344 9.42844L9.62156 22.4691H10.5544L8.17781 8.90344L3.64031 5.655ZM20.4356 17.8612L17.3512 17.6175L15.0169 22.2675L18.2887 20.5472L20.4356 17.8612ZM0 16.5487L1.99078 22.4878H3.44344L2.12625 17.5472L0 16.5487Z"
              fill="white"
            />
          </svg>
    </div>
  )
}

export default Svg9