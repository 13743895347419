import 'tailwindcss/tailwind.css';
import {
  MemoryRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './App.css';

import { Suspense } from 'react';
import LoginPage from './pages/Login/LoginPage';
import Center from './pages/OnBoarding/CenterDetails';
import HomePage from './pages/Home/HomePage';
import Amenities from './pages/OnBoarding/Amenities';
import Timing from './pages/OnBoarding/Timing';
import routes from './route/routes';
import Loading from './components/Loading/Loading';
import DashboardLayout from './layout/Dashboard/DashboardLayout'

function PrivateRoute( {element} ) {
  const hasValidToken = !!localStorage.getItem('Token'); // Check if a hashed token exists
  if (!hasValidToken) {
    return <Navigate to="/" />;
  }
  return element;
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/onboarding/Details"
          element={<PrivateRoute element={<Amenities />} />}
        />
        <Route
          path="/onboarding/Timing"
          element={<PrivateRoute element={<Timing />} />}
        />
        <Route
          path="/onboarding/Amenities"
          element={<PrivateRoute element={<Center />} />}
        />
        <Route element={<DashboardLayout />}>
          <Route
            path="/home"
            element={<PrivateRoute element={<><HomePage/></>} />}
          />
          {routes.map(({ path, component: Component }) => (
            <Route
              path={path}
              element={
                <Suspense fallback={<Loading />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>
      </Routes>
    </Router>
  );
}
