import React from 'react';
import { crossIcon } from '../../constant/Images';

function ErrorMessage({ message }) {
  return (
    <div className="absolute bottom-5 left-5 text-white py-5 px-10 rounded-2xl mb-4 bg-red-500">
      <img
        src={crossIcon}
        className="h-16  -left-2 -top-2 absolute"
        alt="cross"
      />
      <p className="m-0 p-0 text-2xl ">Oh Snap!</p>
      <p className="m-0 p-0 text-xs">{message}</p>
    </div>
  );
}

export default ErrorMessage;
