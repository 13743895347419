import React from 'react';
import { Link } from 'react-router-dom';
import MainCardOne from './MainCardOne';
import MainCardTwo from './MainCardTwo';
import EditListingCard from './EditListingCard';
import LeadsCard from './LeadsCard';
import CheckInCard from './CheckInCard';

function Listing() {
  return (
    <div className="w-full">
      <div className="p-2 bg-purple-500 flex justify-between rounded">
        <h1>Fitverse Listing</h1>
        <h1>IC</h1>
      </div>
      <div>
        <img
          src="https://fitverse.fit/assets/avator/upload/gym/758797859039a2836jpg.JPG"
          alt="LISTING"
          className="h-50 w-full mt-2 rounded"
        />
      </div>
    </div>
  );
}

function HomePage() {
  return (
    <div className="flex">
      <div className="w-4/5 h-full">
        <MainCardOne />
        {/* Page performance  */}
        {/* <MainCardTwo /> */}
        <LeadsCard/>
      </div>
      <div className="w-1/4 bg-#121212 ml-5 " >
        <EditListingCard/>
        <CheckInCard/>
      </div>
    </div>
  );
}

export default HomePage;
